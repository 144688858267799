/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import jwt_decode from 'jwt-decode';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import AppModal from 'components/modal';
import CallService from 'services/CallService';

import AuthService from 'services/AuthService';
import { useSnackbar } from 'notistack';
import AppResource from '../utils';
import Logo from '../assets/logo/Logo';
import icon1 from '../assets/logo/icon1.svg';
import icon2 from '../assets/logo/icon2.svg';
import icon3 from '../assets/logo/icon3.svg';
import icon4 from '../assets/logo/icon4.svg';
import icon5 from '../assets/logo/icon5.svg';
import icon6 from '../assets/logo/icon6.svg';
import icon1B1 from '../assets/logo/icon1_bl.svg';
import icon2B1 from '../assets/logo/icon2_bl.svg';
import icon3B1 from '../assets/logo/icon3_bl.svg';
import icon4B1 from '../assets/logo/icon4_bl.svg';
import icon5B1 from '../assets/logo/icon5_bl.svg';
import icon6B1 from '../assets/logo/icon6_bl.svg';
import logout from '../assets/logo/logout.svg';
import User from '../assets/images/user.jpg';
import staffimg from './images.jpeg';
import { Desk } from './components/Desk';

const sidebarList = [
  {
    id: 0,
    path: '/app/summary',
    name: 'Summary',
    icon: icon1,
    img: icon1B1
  },
  {
    id: 1,
    path: '/app/call',
    name: 'Call',
    icon: icon2,
    img: icon2B1
  },
  {
    id: 2,
    path: '/app/counter',
    name: 'Counter',
    icon: icon3,
    img: icon3B1
  },
  {
    id: 3,
    path: '/app/services',
    name: 'Services',
    icon: icon4,
    img: icon4B1
  },
  {
    id: 4,
    path: '/app/staff',
    name: 'Staff',
    icon: icon5,
    img: icon5B1
  },
  {
    id: 5,
    path: '/app/settings',
    name: 'Settings',
    icon: icon6,
    img: icon6B1
  }
];

const MyApp = ({ children, assignedDesk, setAssignedDesk }) => {
  const [user, setuser] = useState({});
  const [deskChoose, setDeskChoose] = useState(false);
  const location = useLocation();
  const [callIndex, setCallIndex] = useState(
    location?.pathname === '/app/call' ? location?.pathname : null
  );
  const [menuBoxClass, setMenuBoxClass] = useState('sidebar-close');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const refresh = localStorage.getItem('refresh');

  const handleLogout = async () => {
    const data = {
      refresh
    };
    const res = await AuthService.logout(data);
    if (res?.status === 200) {
      enqueueSnackbar(res?.data?.res, {
        variant: 'success'
      });
      navigate('/');
      setAssignedDesk();
    } else {
      res?.data.errors.map((item) =>
        enqueueSnackbar(`${item.field} : ${item.message}`, {
          variant: 'error'
        })
      );
    }
    localStorage.clear();
    navigate('/');
  };

  window.onload = function () {
    handleLogout();
  };

  useEffect(() => {
    window.onbeforeunload = function (e) {
      e.returnValue =
        'If you refresh the page, you will be logged out & the current token will be discarded';
      return e.returnValue;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const [openDesk, setOpenDesk] = useState(false);

  async function getDesk() {
    const res = await CallService.getDesk();
    if (res?.status === 200) {
      setAssignedDesk(res?.data);
    } else {
      enqueueSnackbar(res?.data?.res, {
        variant: 'error'
      });
    }
  }

  useEffect(() => {
    getDesk();
  }, []);

  const handlNextPage = (item) => {
    navigate(item.path);
    if (item.path == '/app/call') {
      setCallIndex(item.path);
    } else {
      setCallIndex(null);
      setDeskChoose(false);
    }
  };

  const handleMenuBar = () => {
    setMenuBoxClass('sidebar-open');
  };

  const closeSidebar = () => {
    setMenuBoxClass('sidebar-close');
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
    const userDetail = localStorage.getItem('token');
    const decodedDetail = jwt_decode(userDetail);
    setuser(decodedDetail);
  }, [navigate, setuser]);

  const firstName = user.first_name ? user.first_name.toLowerCase() : '';
  const lastName = user.last_name ? user.last_name.toLowerCase() : '';

  const handleOpenDesk = () => {
    setOpenDesk(true);
    if (callIndex) {
      setDeskChoose(true);
    } else {
      setDeskChoose(false);
    }
  };

  const handleCloseDesk = () => {
    setOpenDesk(false);
  };

  const renderProfileImage = () => {
    if (user?.upload_image)
      return <img className="userImg" src={user.upload_image} alt="" />;
    return <img className="userImg sidebar_userimg" src={User} alt="" />;
  };

  const renderDesk = () => {
    return (
      callIndex && (
        <p
          onClick={() => handleOpenDesk()}
          className="my-stl"
          style={{ fontSize: '12px', cursor: 'pointer' }}
          aria-hidden="true"
        >
          <option>
            {assignedDesk ? assignedDesk.name : 'Choose your Desk'}
          </option>
        </p>
      )
    );
  };

  const renderDeskModal = () => {
    return (
      openDesk &&
      deskChoose && (
        <AppModal
          handleClose={() => handleCloseDesk()}
          open={openDesk}
          title="Change Desk"
        >
          <Desk
            handleCloseDesk={handleCloseDesk}
            assignedDesk={assignedDesk}
            setAssignedDesk={setAssignedDesk}
          />
        </AppModal>
      )
    );
  };

  return (
    <Box
      className={`position-relative ${menuBoxClass}`}
      sx={{ display: 'flex', overflow: 'auto' }}
    >
      <CssBaseline />
      <Drawer
        className="sidebar"
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            border: 'none',
            overflowX: 'hidden'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Box>
          <Logo height="33px" width="100%" />
          <button
            className="close-btn d-xl-none"
            type="button"
            onClick={closeSidebar}
          >
            <CloseIcon />
          </button>
          <List className="menuList">
            {sidebarList.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  onClick={() => {
                    handlNextPage(item);
                  }}
                  button
                  sx={{ pl: 5, ml: 0, mb: 2 }}
                  className={item.path === location.pathname ? 'active' : ''}
                >
                  <img
                    src={item.path === location.pathname ? item.img : item.icon}
                    alt=""
                  />
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 20 }}
                    sx={{ ml: 2 }}
                    style={{}}
                    primary={item.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box className="mt-auto">
          <List className="menuList mt-0">
            <ListItem
              onClick={handleLogout}
              button
              sx={{ pl: 5, ml: 0 }}
              style={{ color: AppResource.colors.greyColor2 }}
            >
              <img src={logout} alt="" />
              <ListItemText
                primaryTypographyProps={{ fontSize: 20 }}
                sx={{ ml: 2 }}
                style={{}}
                primary="Logout"
              />
            </ListItem>
            <ListItem>
              <div className="sidebarLine" />
            </ListItem>
            <ListItem sx={{ pl: 5, ml: 0, mt: 1, pb: 2 }}>
              {renderProfileImage()}
              <Box>
                <p className="userName text-capitalize">
                  {`${firstName} ${lastName}`}
                </p>
                {renderDesk()}
              </Box>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        className="main-content pb-4 pb-lg-5 ml-0"
        sx={{
          flexGrow: 1,
          bgcolor: AppResource.colors.blColor2,
          mt: 2,
          ml: 0,
          borderTopLeftRadius: 43,
          height: '100vh',
          pl: 7,
          pr: 7,
          overflow: 'auto'
        }}
      >
        {children}
        <button
          type="button"
          className="d-xl-none menu-bar-Icon"
          onClick={handleMenuBar}
        >
          <MenuIcon />
        </button>
      </Box>
      {renderDeskModal()}
    </Box>
  );
};

export default MyApp;
