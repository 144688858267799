/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Toolbar, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { find } from 'lodash';
import AppService from 'services/AppService';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import StaffService from 'services/StaffService';
import Delete from '../../../../../assets/logo/delete.svg';
import Edite from '../../../../../assets/logo/edit.svg';
import User from '../../../../../assets/images/user.jpg';
import ListUser from './components/ListUser';
import AppModal from '../../../../../components/modal';
import AddUser from './components/AddUser';
import AddStaff from '../../AddStaff';

const StaffDetail = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [staff, setStaff] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [openDeleteStaff, setDeleteStaff] = useState(false);

  const [departmentData, setDepartmentData] = useState();
  const [deskData, setDeskData] = useState();
  const [serviceData, setServiceData] = useState([]);

  const param = useParams();
  const navigate = useNavigate();

  const [updateUserList, setUpdateUserList] = useState(false);

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  async function getDepartmentsData() {
    const res = await StaffService.getDepartmentsData();
    setDepartmentData(res.data);
  }

  async function getDesksData() {
    const res = await StaffService.getDesksData();
    setDeskData(res.data);
  }

  async function getServiceData() {
    const res = await AppService.getServiceList();
    setServiceData(res?.data);
  }

  let departmentName = null;

  if (departmentData) {
    departmentName = find(departmentData, {
      id: staff && staff.department
    });
  }

  const serviceName = [];
  if (serviceData && staff && staff.service) {
    serviceData.map((d) => {
      if (staff.service.indexOf(d.id) >= 0) {
        serviceName.push(d);
      }
    });
  }

  let deskName = null;
  if (deskData) {
    deskName = find(deskData, {
      id: staff && staff.desk
    });
  }

  async function getStaff() {
    const res = await StaffService.getStaffData(param.id);
    setStaff(res.data);
  }

  useEffect(() => {
    getStaff();
    getDepartmentsData();
    getDesksData();
    getServiceData();
  }, []);

  const handleDelete = () => {
    setDeleteStaff(true);
  };

  const confirmDeleteStaff = async () => {
    const res = await StaffService.deleteStaff(param.id);
    if (res && res.status === 200) {
      enqueueSnackbar('Staff Deleted Successfully', {
        variant: 'success'
      });
      setDeleteStaff(false);
    } else {
      res?.msg.errors.map((item) =>
        enqueueSnackbar(item.message, {
          variant: 'error'
        })
      );
    }
    setDeleteStaff(false);
    navigate(`/app/staff`);
  };

  const renderServiceName = () => {
    return (
      <div>
        {serviceName.map((item) => {
          return <span key={item.id}>{item.name}</span>;
        })}
      </div>
    );
  };

  const handleEdit = (e) => {
    setOpenEdit(true);
  };

  const renderDeleteEditButton = () => {
    if (
      decodedDetail?.is_manager === 'yes' &&
      staff?.staff_manager === decodedDetail?.user_id
    ) {
      return (
        <div>
          <span onClick={handleDelete} aria-hidden="true">
            <img
              style={{ marginRight: '20px', cursor: 'pointer' }}
              src={Delete}
              alt=""
            />
          </span>
          <span onClick={handleEdit} aria-hidden="true">
            <img style={{ cursor: 'pointer' }} src={Edite} alt="" />
          </span>
        </div>
      );
    }
    return null;
  };

  const renderAddUsersButton = () => {
    if (
      decodedDetail?.is_manager === 'yes' &&
      staff?.staff_manager === decodedDetail?.user_id
    ) {
      return (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setOpenUser(true)}
          sx={{
            marginLeft: '20px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            borderRadius: '7px',
            backgroundColor: 'white'
          }}
        >
          Add Users
        </Button>
      );
    }
    return null;
  };

  const renderUploadImage = () => {
    if (staff?.upload_image)
      return <img className="staff-edit-img" src={staff.upload_image} alt="" />;
    return <img className="staff-edit-img" src={User} alt="" />;
  };

  return (
    <div className="staff-edit-root">
      <AppBar position="static" className="bg-none" sx={{ color: 'black' }}>
        <Toolbar className="common-top-head staff-top">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <h1>Staff</h1>
            {renderAddUsersButton()}
          </div>
        </Toolbar>
      </AppBar>
      <Box className="staff-edit-content">
        <Box className="staff-edit-head">
          {renderUploadImage()}
          <Box className="staff-edit-infos">
            <Box className="staff-edit-infos-top">
              <p>
                <Tooltip title={staff && staff.name} placement="top-start">
                  <span className="staff-edit-name d-block mb-1">
                    {staff && staff.name}
                  </span>
                </Tooltip>
                <span className="staff-edit-profil d-block fw-700">
                  {departmentName && departmentName.name}
                  {', '}
                  {deskName && deskName.name}
                </span>

                <span className="d-block">{staff && staff.email}</span>

                <span className="d-block">{staff && staff.contact}</span>

                {moment(staff && staff.publication_date).format('DD/MM/YYYY')}
              </p>
            </Box>
            <Box
              style={{
                width: '100px',
                position: 'absolute',
                right: '0',
                top: '0',
                marginTop: '150px'
              }}
            >
              {renderDeleteEditButton()}
            </Box>
            <Box className="staff-edit-info-services">
              <Box
                style={{
                  marginTop: '-22px'
                }}
              >
                {renderServiceName()}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <ListUser
            setUpdateUserList={setUpdateUserList}
            updateUserList={updateUserList}
          />
        </Box>
      </Box>
      <AppModal
        handleClose={() => setOpenUser(false)}
        open={openUser}
        title="Add New User"
      >
        <AddUser
          setUpdateUserList={setUpdateUserList}
          updateUserList={updateUserList}
          setOpenUser={setOpenUser}
        />
      </AppModal>
      <AppModal
        handleClose={() => setDeleteStaff(false)}
        open={openDeleteStaff}
        title="Delete Staff"
      >
        <Box>
          <span className="d-block h-20">
            Are you sure, you want to delete ?
          </span>
          <Button
            className="mt-4"
            variant="contained"
            color="warning"
            type="submit"
            onClick={(e) => confirmDeleteStaff(e)}
          >
            Delete
          </Button>
        </Box>
      </AppModal>
      {openEdit && (
        <AddStaff
          open={openEdit}
          setOpen={setOpenEdit}
          staff={staff}
          getStaff={getStaff}
          isUpdate
        />
      )}
    </div>
  );
};

export default StaffDetail;
