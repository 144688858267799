/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import AppService from 'services/AppService';
import InputSearch from 'components/form/InputSearch';
import jwt_decode from 'jwt-decode';
import AddIcon from '@mui/icons-material/Add';
import { Button, Box, Toolbar, AppBar } from '@mui/material';
import AppLoader from 'components/loading/AppLoader';
import AddService from './components/AddService';
import ServiceListing from './components/ServiceListing';

const Services = () => {
  const [listData, setListData] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [addServiceForm, setAddServiceForm] = useState(false);
  const handleClickOpen = () => {
    setAddServiceForm(true);
  };

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  async function getServices() {
    const res = await AppService.getServiceDetail();
    const statuss = res?.status;
    if (res?.data && statuss === 200) {
      setLoading(false);
      setListData(res?.data);
    }
  }

  useEffect(() => {
    getServices();
  }, []);

  const handleClose = () => {
    setAddServiceForm(false);
  };

  const handleSearch = async (e) => {
    const {
      target: { value }
    } = e;
    const res = await AppService.searchData('service', value);
    if (res && res.status === 200) {
      setListData(res.data);
      setError('');
    }
    if (res && res.status === 404) {
      setError(res);
    }
  };

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  const renderAddButton = () => {
    if (decodedDetail?.is_manager === 'yes') {
      return (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          sx={{
            marginLeft: '20px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            borderRadius: '7px',
            backgroundColor: 'white'
          }}
        >
          Add New
        </Button>
      );
    }
    return null;
  };

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: '48px' }}>
      <AppBar position="static" className="bg-none" sx={{ color: 'black' }}>
        <Toolbar className="px-0 common-top-head">
          <div className="d-flex justify-content-between w-100">
            <h1>Services</h1>
            <div className="d-flex align-items-center counter-search">
              <InputSearch handleSearch={handleSearch} />
              {renderAddButton()}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <ServiceListing
        listData={listData}
        NoDataFound={error}
        getServices={getServices}
      />
      {addServiceForm && (
        <AddService
          addServiceForm={addServiceForm}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          getServices={getServices}
        />
      )}
      {renderLoader()}
    </Box>
  );
};

export default Services;
