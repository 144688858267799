const AppResource = {
  colors: {
    blueColor: '#1581FC',
    blueColor2: '#154360',
    redColor: '#F39C12',
    blColor: '#FFFFFF',
    blColor2: '#F6F9FF',
    greyColor: '#9A9A9A',
    greyColor2: '#4B5662',
    violetColor: '#D991FD',
    jauneColor: '#FBDF47',
    orangeColor: '#F38684'
  },
  shadow: {
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px',
    boxShadow2: '#34495E 0px 7px 29px 0px'
  }
};
export default AppResource;
