/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Grid, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StaffService from 'services/StaffService';
import AppService from 'services/AppService';
import jwt_decode from 'jwt-decode';
import AppLoader from 'components/loading/AppLoader';
import InputSearch from '../../components/form/InputSearch';
import ItemStaff from './components/ItemStaff';
import AddStaff from './components/AddStaff';

const Staff = () => {
  const [staffs, setstaffs] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  async function getStaffs() {
    const res = await StaffService.getStaffsData();
    if (res && res.status === 200) {
      setLoading(false);
      setstaffs(res.data);
    }
  }

  useEffect(() => {
    getStaffs();
  }, []);

  const handleSearch = async (e) => {
    const {
      target: { value }
    } = e;
    const res = await AppService.searchData('staff', value);
    if (res && res.status === 200) {
      setstaffs(res.data);
      setError('');
    }
    if (res && res.status === 404) {
      setError(res);
    }
  };

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  const renderAddNewButton = () => {
    if (decodedDetail?.is_manager === 'yes') {
      return (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
          sx={{
            marginLeft: '20px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            borderRadius: '7px',
            backgroundColor: 'white'
          }}
        >
          Add Staff
        </Button>
      );
    }
    return null;
  };

  return (
    <Box minWidth="sm md lg">
      <AppBar position="static" className="bg-none" sx={{ color: 'black' }}>
        <Toolbar className="common-top-head px-0">
          <div className="d-flex justify-content-between w-100">
            <h1>Staff</h1>
            <div className="d-flex align-items-cener counter-search">
              <InputSearch handleSearch={handleSearch} />
              {renderAddNewButton()}
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={2}
        style={{
          marginTop: '10px'
        }}
        className="staffListRow"
      >
        <ItemStaff staffs={staffs} getStaffs={getStaffs} NoDataFound={error} />
      </Grid>
      {open && <AddStaff open={open} setOpen={setOpen} getStaffs={getStaffs} />}
      {renderLoader()}
    </Box>
  );
};

export default Staff;
