import React from 'react';
import { Card, Grid } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import CardChart from './chart/CardChart';
import AppResource from '../../utils';
import ListSummaryServices from './summaryervice/ListSummaryServices';
import SubTitle from '../../components/SubTitle';

const Summary = () => {
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300
    }
  ];

  return (
    <div className="summary">
      <Grid>
        <SubTitle title="Summary" time />
        <Grid container>
          <Grid item xs={12} lg={6} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CardChart background={AppResource.colors.blueColor} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardChart background={AppResource.colors.violetColor} />
            </Grid>
            <Grid item xs={12} sm={6} className="dashVisitorGrid">
              <CardChart background={AppResource.colors.jauneColor} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardChart background={AppResource.colors.orangeColor} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} container>
            <Grid item xs={12} sm={6} className="mt-3 mt-xl-0">
              <Card className="ml-xl-3 users-card rounded-15 shadow-none pt-4">
                {' '}
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} className="mt-3 mt-xl-0">
              <Card className="ml-sm-3 users-card pt-4 rounded-15 shadow-none">
                {' '}
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <div className="mt-4 mt-xl-5">
          <ListSummaryServices />
        </div>
      </Grid>
    </div>
  );
};

export default Summary;
