import { Button, Input } from '@mui/material';
import React, { useState } from 'react';

const ImageUploader = ({ setFieldValue, name, uploadImage }) => {
  const [uploadedImageName, setUploadedImageName] = useState('');
  const handleUploadChange = (e) => {
    const obj = setFieldValue('upload_image', e.target.files[0]);
    const fileList = Array.from(e.target.files);
    const uploadedImage = fileList.map((item) => item.name);
    setUploadedImageName(uploadedImage);
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    formData.append('pic', fileList);
  };

  const handleReset = (e) => {
    setUploadedImageName(null);
  };

  const renderUploadImage = () => {
    if (uploadedImageName) {
      return (
        <Input
          value={uploadedImageName}
          onChange={handleUploadChange}
          disabled
          className="upload-file uploaded-file"
        />
      );
    }
    return (
      <Input
        name="upload_image"
        notRequired
        type="file"
        accept="image/*"
        onChange={handleUploadChange}
        className="upload-file"
      />
    );
  };

  return (
    <div>
      <div className="uploaded-image position-relative">
        {renderUploadImage()}
        <Button onClick={(e) => handleReset(e)}>Reset</Button>
      </div>
      {uploadImage && (
        <div className="text-center mt-3">
          <img
            style={{
              maxWidth: '100%'
            }}
            className="img-fluid"
            src={uploadImage}
            alt="service"
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
