/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Field, Form } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
import {
  Input,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddService from '..';

export const FormikFields = ({
  isValid,
  isSubmitting,
  handleSubmit,
  handleClickOpen,
  handleClose,
  addServiceForm,
  setFieldValue,
  openAddSubService,
  addSubService,
  handleCloseAddSubService
}) => {
  const isBtnDisabled = !isValid || isSubmitting;
  const [uploadedImageName, setUploadedImageName] = useState('');

  const handleUploadChange = (e) => {
    const obj = setFieldValue('upload_image', e.target.files[0]);
    const fileList = Array.from(e.target.files);

    const uploadedImage = fileList.map((item) => item.name);
    setUploadedImageName(uploadedImage);
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    formData.append('pic', fileList);
  };

  const renderNameField = () => {
    return addSubService && addSubService.id ? (
      <Field
        name="name"
        type="text"
        label="Sub Service Name"
        component={FormikInputField}
      />
    ) : (
      <Field
        name="name"
        type="text"
        label="Service Name"
        component={FormikInputField}
      />
    );
  };

  const renderTitle = () => {
    return addSubService && addSubService.id
      ? 'Add New Sub Service'
      : 'Add New Service';
  };

  const handleReset = (e) => {
    setUploadedImageName(null);
  };

  const renderUploadImage = () => {
    if (uploadedImageName) {
      return (
        <Input
          value={uploadedImageName}
          onChange={handleUploadChange}
          disabled
          className="upload-file uploaded-file"
        />
      );
    }
    return (
      <Input
        name="upload_image"
        notRequired
        type="file"
        accept="image/*"
        onChange={handleUploadChange}
        className="upload-file"
      />
    );
  };

  return (
    <Box>
      <Dialog open={addServiceForm} onClose={handleClose}>
        <Form>
          <DialogTitle style={{ fontWeight: 'bold' }}>
            {renderTitle()}
            <Button onClick={handleClose} className="closeIcon">
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            {renderNameField()}

            <Field
              name="acronym"
              type="text"
              label="Acronym"
              component={FormikInputField}
            />
            <Field
              name="description"
              type="text"
              label="Description"
              component={FormikInputField}
            />

            <div className="uploaded-image position-relative">
              {renderUploadImage()}
              <Button onClick={(e) => handleReset(e)}>reset</Button>
            </div>

            <Button
              variant="contained"
              type="button"
              color="primary"
              disabled={isBtnDisabled}
              onClick={handleSubmit}
              fullWidth
              size="large"
              className="mt-4"
            >
              create
            </Button>
          </DialogContent>
        </Form>
      </Dialog>
      {addServiceForm && (
        <AddService
          openAddSubService={openAddSubService}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          handleCloseAddSubService={handleCloseAddSubService}
        />
      )}
    </Box>
  );
};
