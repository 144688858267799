/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_URL;
class StaffService {
  async getEmployees() {
    try {
      const response = await axios.get(`${API_URL}api/employees`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async getDesksData() {
    try {
      const response = await axios.get(`${API_URL}api/desks`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getDepartmentsData() {
    try {
      const response = await axios.get(`${API_URL}api/departments`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getStaffsData() {
    try {
      const response = await axios.get(`${API_URL}api/v1/staffs`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async createStaff(data) {
    try {
      const response = await axios.post(`${API_URL}api/v1/staff`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async editStaff(id, data) {
    try {
      const response = await axios.put(`${API_URL}api/v1/staffs/${id}`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async addEmployees(id, data) {
    try {
      const response = await axios.patch(
        `${API_URL}api/v1/staffs/${id}`,
        { employee: data },
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async getStaffData(data) {
    try {
      const response = await axios.get(`${API_URL}api/v1/staffs/${data}`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async deleteStaff(data) {
    try {
      const response = await axios.delete(`${API_URL}api/v1/staffs/${data}`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async deleteUser(data) {
    try {
      const response = await axios.delete(`${API_URL}api/v1/staff/employee`, {
        data,
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }
}

export default new StaffService();
