/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AppModal from 'components/modal';
import CallService from 'services/CallService';
import { useSnackbar } from 'notistack';
import image from '../../../myApp/images.jpeg';
import User from '../../../assets/images/user.jpg';
import InputSearch from '../../../components/form/InputSearch';

const TransferToken = ({
  handleClose,
  counterDetail,
  callData,
  setCallData,
  reset
}) => {
  const [openConfirmationToken, setConfirmationToken] = useState(false);
  const [transferTokenList, setTransferToken] = useState([]);
  const [transferTokenSelected, setTransferTokenSelected] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getTransferToken() {
      const response = await CallService.getTransferToken(callData?.token_id);
      if (response?.data && response?.status === 200) {
        setTransferToken(response?.data);
      }
    }
    getTransferToken();
  }, []);

  const transferToken = async () => {
    const data = {
      token_id: callData?.token_id,
      transfer_to: transferTokenSelected?.employee_id,
      transfer_counter: callData?.counter_id,
      transfer_desk: transferTokenSelected?.desk_id
    };

    const res = await CallService.transferToken(data);
    if (res?.status === 200) {
      enqueueSnackbar('Tranfered Token successfully', {
        variant: 'success'
      });
      setCallData(null);
      reset('', false);
    } else {
      res?.data.errors.map((item) =>
        enqueueSnackbar(`${item.field} : ${item.message}`, {
          variant: 'error'
        })
      );
    }
    handleClose();
    setConfirmationToken(true);
  };

  const handleTokenSelection = (e, item) => {
    setTransferTokenSelected(item);
  };

  const renderUploadImage = (item) => {
    if (item?.upload_image)
      return <img src={item.upload_image} className="transferUserImg" alt="" />;
    return <img src={User} className="transferUserImg" alt="" />;
  };

  const renderTransferToken = () => {
    if (transferTokenList.length === 0) {
      return <div className="text-center">No Data available</div>;
    }
    return transferTokenList?.map((item) => {
      return (
        <ListItem
          className={
            transferTokenSelected?.desk_id === item?.desk_id
              ? 'background-color-lightblue'
              : ''
          }
          key={item.desk_id}
          secondaryAction={<span edge="end">{item.desk_name}</span>}
          onClick={(e) => handleTokenSelection(e, item)}
        >
          <ListItemAvatar>
            <Avatar>{renderUploadImage(item)}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.employee_name} />
        </ListItem>
      );
    });
  };

  return (
    <>
      <Box className="transferToken">
        <InputSearch border />
        <List>{renderTransferToken()}</List>
        <Button
          className="mt-4"
          variant="contained"
          fullWidth
          size="large"
          onClick={() => transferToken()}
        >
          Transfer
        </Button>
      </Box>
      {openConfirmationToken && (
        <AppModal
          handleClose={() => setConfirmationToken(false)}
          open={openConfirmationToken}
          title="Confirmation"
        >
          <Box className="confirmBox">
            <p className="h-18 grey-color mb-3">
              Are you sure you want to transfer to new desk?
            </p>
            <div className="mb-2">
              <span className="d-block">Token Number</span>
              <h2 className="h-24 fw-700 text-primary">024</h2>
            </div>
            <div className="mb-2">
              <span className="d-block">Transfer</span>
              <h2 className="h-24 fw-700 text-primary">5</h2>
            </div>
            <div className="mb-0">
              <span className="d-block">Transferred by</span>
              <h2 className="h-16 fw-600">George Brown D1 and C3</h2>
            </div>
          </Box>
          <Box className="text-right mt-4">
            <Button variant="outlined" size="large" className="mr-3 btnGrey">
              Cancel
            </Button>
            <Button variant="contained" size="large">
              Confirm
            </Button>
          </Box>
        </AppModal>
      )}
    </>
  );
};

export default TransferToken;
