import * as Yup from 'yup';

export const AddServiceSchema = Yup.object().shape({
  name: Yup.string().required('Service Name is required.'),
  description: Yup.string().required('Description is required.'),
  acronym: Yup.string().required('Acronym is required.')
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required.')
});

export const AddStaffSchema = Yup.object().shape({
  name: Yup.string().required('Staff Name is required.'),
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required.'),
  contact: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8)
    .required('Contact number is required'),
  department: Yup.number().required('Department is required')
});
