/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import AppResource from 'utils';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SubServiceListing from './SubServiceListing';
import Edite from '../../../../../assets/logo/edit.svg';
import Delete from '../../../../../assets/logo/delete.svg';
import User from '../../../../../assets/images/user.jpg';

export const ParentTableRow = ({
  selected,
  index,
  row,
  handleAddSubService,
  handleDelete,
  handleEdit,
  setSelected
}) => {
  const [showSubService, setShowSubService] = useState(false);
  const handleChangeSelected = () => {
    setShowSubService(!showSubService);
  };

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const renderArrowButton = () => {
    return (
      row.child > 0 && (
        <IconButton
          sx={{ color: '#1581FC' }}
          onClick={(e) => {
            handleChangeSelected(e, row, index);
          }}
          aria-label="delete"
        >
          {showSubService ? (
            <ArrowDropDownIcon fontSize="large" />
          ) : (
            <PlayArrowIcon />
          )}
        </IconButton>
      )
    );
  };
  const { upload_image, name, child, acronym, description, publication_date } =
    row;

  const renderActions = () => {
    if (decodedDetail?.is_manager === 'yes') {
      return (
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          <IconButton onClick={(e) => handleDelete(e, row)} aria-label="delete">
            <img src={Delete} alt="" />
          </IconButton>
          <IconButton
            sx={{ ml: 1 }}
            onClick={(e) => handleEdit(e, row)}
            aria-label="edit"
          >
            <img src={Edite} alt="" />
          </IconButton>
          <IconButton
            onClick={(e) => handleAddSubService(e, row)}
            sx={{ ml: 1 }}
            aria-label="edit"
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      );
    }
    return null;
  };

  const renderUploadImage = () => {
    if (upload_image) {
      return <img src={upload_image} alt="" className="image_service" />;
    }
    return <img src={User} alt="" className="image_service" />;
  };

  const renderParentTable = () => {
    if (row?.res === 'Service are not available') {
      return (
        <TableCell
          colSpan={decodedDetail?.is_manager === 'yes' ? 7 : 6}
          align="center"
          style={{ borderBottom: 'none', textTransform: 'capitalize' }}
        >
          {row.res}
        </TableCell>
      );
    }
    return (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          sx={{ color: AppResource.colors.greyColor2 }}
          component="th"
          scope="row"
        >
          <IconButton
            sx={{ color: '#1581FC' }}
            onClick={(e) => {
              handleChangeSelected(e, row, index);
            }}
            aria-label="delete"
          >
            {renderArrowButton()}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{ color: AppResource.colors.greyColor2 }}
          component="td"
          scope="row"
        >
          <Avatar sx={{ bgcolor: AppResource.colors.blueColor }}>
            {renderUploadImage()}
          </Avatar>
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          {name}
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          {acronym}
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          <Tooltip title={description} placement="top">
            <div className="description">{description}</div>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          {moment(publication_date).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell
          sx={{ color: AppResource.colors.greyColor2, textAlign: 'center' }}
          align="left"
        >
          {child}
        </TableCell>
        {renderActions()}
      </TableRow>
    );
  };

  return (
    <>
      {renderParentTable()}
      {showSubService && (
        <SubServiceListing
          selected={selected}
          setSelected={setSelected}
          index={index}
          row={row}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleAddSubService={handleAddSubService}
          handleChangeSelected={handleChangeSelected}
        />
      )}
    </>
  );
};
