import React from 'react';
import { Box } from '@mui/material';
import logo from './logo2.png';

function Logo(props) {
  const { width, height } = props;
  return (
    <Box style={{ width }}>
      <img
        src={logo}
        style={{ display: 'block', height, margin: '0px auto' }}
        alt=""
      />
    </Box>
  );
}

export default Logo;
