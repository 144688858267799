/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import AppResource from 'utils';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SubServiceListing from './SubServiceListing';
import Edite from '../../../../../assets/logo/edit.svg';
import Delete from '../../../../../assets/logo/delete.svg';

export const ChildTableRow = ({
  selected,
  index,
  row,
  handleAddSubService,
  handleDelete,
  handleEdit,
  setSelected
}) => {
  const [showSubService, setShowSubService] = useState(false);
  const handleChangeSelected = () => {
    setShowSubService(!showSubService);
  };

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const renderArrowButton = () => {
    return (
      row.child > 0 && (
        <IconButton
          sx={{ color: '#1581FC' }}
          onClick={(e) => {
            handleChangeSelected(e, row, index);
          }}
          aria-label="delete"
        >
          {showSubService ? (
            <ArrowDropDownIcon fontSize="large" />
          ) : (
            <PlayArrowIcon />
          )}
        </IconButton>
      )
    );
  };
  const { name, child, acronym, description, publication_date } = row;

  const renderActions = () => {
    if (decodedDetail?.is_manager === 'yes') {
      return (
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          <IconButton onClick={(e) => handleDelete(e, row)} aria-label="delete">
            <img src={Delete} alt="" />
          </IconButton>
          <IconButton
            onClick={(e) => handleEdit(e, row)}
            sx={{ ml: 1 }}
            aria-label="edit"
          >
            <img src={Edite} alt="" />
          </IconButton>
          <IconButton
            sx={{ ml: 1 }}
            aria-label="edit"
            onClick={(e) => handleAddSubService(e, row)}
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      );
    }
    return null;
  };

  return (
    <>
      {row.id && (
        <TableRow key={row.id} style={{ transition: '2s' }}>
          <TableCell sx={{ color: AppResource.colors.greyColor2 }} />
          <TableCell
            sx={{ color: AppResource.colors.greyColor2 }}
            component="th"
            scope="row"
          >
            <IconButton
              sx={{ color: '#1581FC' }}
              onClick={(e) => {
                handleChangeSelected(e, row, index);
              }}
              aria-label="delete"
            >
              {renderArrowButton()}
            </IconButton>
          </TableCell>
          <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
            {name}
          </TableCell>
          <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
            {acronym}
          </TableCell>
          <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
            <Tooltip title={description} placement="top">
              <div className="description">{description}</div>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
            {publication_date
              ? moment(publication_date).format('DD/MM/YYYY')
              : '-'}
          </TableCell>
          <TableCell
            sx={{ color: AppResource.colors.greyColor2, textAlign: 'center' }}
            align="left"
          >
            {child}
          </TableCell>
          {renderActions(row)}
        </TableRow>
      )}
      {showSubService && (
        <SubServiceListing
          selected={selected}
          setSelected={setSelected}
          index={index}
          row={row}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleAddSubService={handleAddSubService}
          handleChangeSelected={handleChangeSelected}
        />
      )}
    </>
  );
};
