/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_URL;
class AppService {
  async getData(route) {
    try {
      const response = await axios.get(API_URL + route, {
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  async searchData(key, searchText) {
    try {
      const response = await axios.get(
        `${API_URL}api/search?key=${key}&name=${searchText}`,
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async getServiceData() {
    try {
      const response = await axios.get(`${API_URL}api/services`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getServiceList() {
    try {
      const response = await axios.get(`${API_URL}api/service_list/details/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getServiceDetail() {
    try {
      const response = await axios.get(`${API_URL}api/services/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getSubServiceData(data) {
    try {
      const response = await axios.get(`${API_URL}api/services?id=${data}`, {
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  async createService(data) {
    try {
      const response = await axios.post(`${API_URL}api/service-create/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async deleteService(data) {
    try {
      const response = await axios.delete(`${API_URL}api/service/${data}`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async editService(id, data) {
    try {
      const response = await axios.patch(
        `${API_URL}api/service/update/${id}`,
        data,
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async addData(route, data) {
    try {
      const response = await axios.post(API_URL + route, data, {
        headers: authHeader()
      });

      return response.data;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async handlePostRequest(route, data) {
    try {
      const response = await axios.post(API_URL + route, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async handlePutRequest(route, data) {
    try {
      const response = await axios.put(API_URL + route, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async uploadChunk(route, data, file, currentChunk, chunkSize) {
    try {
      const params = new URLSearchParams();
      params.set('name', file.name);
      params.set('size', file.size);
      params.set('currentChunk', currentChunk);
      params.set('totalChunk', Math.ceil(file.size / chunkSize));
      const headers = {
        'Content-type': 'application/octet-stream',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };

      const response = await axios.post(
        API_URL + route + params.toString(),
        data,
        { headers }
      );

      return response.data;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }
}

export default new AppService();
