/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Formik } from 'formik';
import jwt_decode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import { AddStaffSchema } from 'common/ValidationSchemas/ValidationSchema';
import StaffService from 'services/StaffService';
import AppLoader from 'components/loading/AppLoader';
import { FormikFields } from './components/FormikFields';

const AddStaff = ({ open, setOpen, getStaffs, staff, isUpdate, getStaff }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  return (
    <Formik
      initialValues={{
        name: staff && staff.name ? staff.name : '',
        email: staff && staff.email ? staff.email : '',
        contact: staff && staff.contact ? staff.contact : '',
        staff_manager: decodedDetail && decodedDetail.user_id,
        department: staff && staff.department ? staff.department : null,
        service: staff && staff.service ? staff.service : [],
        upload_image: staff && staff.upload_image ? staff.upload_image : ''
      }}
      validationSchema={AddStaffSchema}
      onSubmit={async (values) => {
        // eslint-disable-next-line prefer-const
        let formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('contact', values.contact);
        formData.append(
          'staff_manager',
          decodedDetail && decodedDetail.user_id
        );
        formData.append('department', values.department);
        values.service.forEach((item) => formData.append('service', item));

        if (values.upload_image?.name) {
          formData.append(
            'upload_image',
            values.upload_image,
            values.upload_image.name
          );
        }

        if (isUpdate) {
          const res = await StaffService.editStaff(staff.id, formData);
          if (res && res.status === 200) {
            enqueueSnackbar('Staff edited Successfully', {
              variant: 'success'
            });
            setLoading(false);
            await getStaff();
            setOpen(false);
            await getStaffs();
          } else {
            res?.msg.errors.map((item) =>
              enqueueSnackbar(item.message, {
                variant: 'error'
              })
            );
            await getStaffs();
          }
          setOpen(false);
        } else {
          const res = await StaffService.createStaff(formData);
          const status = res && res.status;
          if (status === 201) {
            enqueueSnackbar('Staff added Sucessfully', {
              variant: 'success'
            });
            setLoading(false);
            await getStaffs();
          } else {
            res?.msg.errors.map((item) =>
              enqueueSnackbar(item.message, {
                variant: 'error'
              })
            );
            await getStaffs();
          }
          setOpen(false);
        }
        await getStaff();
      }}
      children={(props) => {
        return (
          <>
            <FormikFields
              {...props}
              open={open}
              setOpen={setOpen}
              isUpdate
              staff={staff}
            />
            {renderLoader()}
          </>
        );
      }}
    />
  );
};

export default AddStaff;
