/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_URL;
class CallService {
  async getCountersTokens() {
    try {
      const response = await axios.get(`${API_URL}api/call`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getDesks() {
    try {
      const response = await axios.get(`${API_URL}api/desks/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getDesk() {
    try {
      const response = await axios.get(`${API_URL}api/desk/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async deskSelect(data) {
    try {
      const response = await axios.get(`${API_URL}api/desk/?desk_id=${data}`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getTransferToken(data) {
    try {
      const response = await axios.get(
        `${API_URL}api/transfer/?token_id=${data}`,
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getTransferTokenList() {
    try {
      const response = await axios.get(`${API_URL}api/transfer_list/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getServeToken() {
    try {
      const response = await axios.get(`${API_URL}api/token/serve/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getVisitorList(data) {
    try {
      const response = await axios.get(
        `${API_URL}api/call?counter_id=${data}`,
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getStartWorkTime(data) {
    try {
      const response = await axios.get(
        `${API_URL}api/work_time?key=start&desk=${data}`,
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getPauseWorkTime(data) {
    try {
      const response = await axios.get(
        `${API_URL}api/work_time?key=pause&desk=${data}`,
        {
          headers: authHeader()
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async transferToken(data) {
    try {
      const response = await axios.post(`${API_URL}api/transfer/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async close(data) {
    try {
      const response = await axios.put(`${API_URL}api/close/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default new CallService();
