/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CounterService from 'services/CounterService';
import jwt_decode from 'jwt-decode';
import AppService from 'services/AppService';
import AppLoader from 'components/loading/AppLoader';
import InputSearch from '../../components/form/InputSearch';
import ListCounter from './ListCounter';
import AddCounter from './components/AddCounter';

const Counter = () => {
  const [counterData, setCounterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddCounter, setOpenAddCounter] = useState(false);
  const [error, setError] = useState();

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const handleClickOpen = () => {
    setOpenAddCounter(true);
  };

  const handleClose = () => {
    setOpenAddCounter(false);
  };

  async function getCounters() {
    const res = await CounterService.getCounters();
    const statuss = res?.status;
    if (res?.data && statuss === 200) {
      setLoading(false);
      setCounterData(res?.data);
    }
  }

  useEffect(() => {
    getCounters();
  }, []);

  const handleSearch = async (e) => {
    const {
      target: { value }
    } = e;
    const res = await AppService.searchData('counter', value);
    if (res && res.status === 200) {
      setCounterData(res.data);
      setError('');
    }
    if (res && res.status === 404) {
      setError(res);
    }
  };

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  const renderAddButton = () => {
    if (decodedDetail?.is_manager === 'yes') {
      return (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          sx={{
            marginLeft: '20px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            borderRadius: '7px',
            backgroundColor: 'white'
          }}
        >
          Add New
        </Button>
      );
    }
    return null;
  };

  return (
    <Box minWidth="sm md lg">
      <AppBar position="static" className="bg-none" sx={{ color: 'black' }}>
        <Toolbar className="px-0 common-top-head">
          <div className="d-flex justify-content-between w-100">
            <h1>Counter</h1>
            <div className="d-flex align-items-center counter-search">
              <InputSearch handleSearch={handleSearch} className="ml-auto" />
              {renderAddButton()}
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Box className="rounded-15">
        <ListCounter
          counterData={counterData}
          getCounters={getCounters}
          NoDataFound={error}
        />
        {openAddCounter && (
          <AddCounter
            open={openAddCounter}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
            getCounters={getCounters}
          />
        )}
        {renderLoader()}
      </Box>
    </Box>
  );
};

export default Counter;
