import { Box } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const InputSearch = ({ handleSearch }) => {
  return (
    <Box className="position-relative">
      <input
        onChange={(e) => handleSearch(e)}
        type="text"
        placeholder="Search..."
        className="form-control form-control-md"
      />
      <span className="searchIcon">
        <SearchIcon />
      </span>
    </Box>
  );
};

export default InputSearch;
