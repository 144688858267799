/* eslint-disable no-console */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import StaffService from 'services/StaffService';
import AppService from 'services/AppService';
import image from '../../images.jpeg';
import User from '../../../../../../assets/images/user.jpg';
import InputSearch from '../../../../../../components/form/InputSearch';

const AddUser = ({ setOpenUser, setUpdateUserList, updateUserList }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [employeesList, setEmployeesList] = useState();
  const [staff, setStaff] = useState([]);
  const [checkedCheckBox, setCheckedCheckBox] = useState([]);
  const param = useParams();
  const [error, setError] = useState();

  async function getEmployees() {
    const res = await StaffService.getEmployees();
    setEmployeesList(res.data);
  }
  let array = [];
  async function getStaff() {
    const res = await StaffService.getStaffData(param.id);
    setStaff(res.data);
    res.data.employee.map((emp) => {
      array.push(emp);
    });
    setCheckedCheckBox(array);
  }

  useEffect(() => {
    getEmployees();
    getStaff();
  }, []);

  const handleCheckbox = (e, id) => {
    if (e.target.checked === false) {
      let removedCheckbox = checkedCheckBox.filter((item) => {
        return item !== id;
      });
      setCheckedCheckBox(removedCheckbox);
    } else {
      setCheckedCheckBox([...checkedCheckBox, id]);
    }
  };

  const renderUploadImage = (item) => {
    if (item.upload_image) return <img src={item.upload_image} alt="" />;
    return <img src={User} alt="" />;
  };

  const renderEmployeeList = () => {
    if (error && error.msg && error.msg.res) {
      return (
        <div style={{ textAlign: 'center' }} item xs={12}>
          {error.msg.res}
        </div>
      );
    }
    if (employeesList) {
      return (
        <div>
          {employeesList.map((item) => {
            return (
              <div
                key={item.id}
                className="userListItem d-flex align-items-center"
              >
                <input
                  name="employee"
                  onChange={(e) => handleCheckbox(e, item.id)}
                  checked={
                    !!checkedCheckBox.find((itemCheck) => item.id === itemCheck)
                  }
                  type="checkbox"
                />
                {renderUploadImage(item)}
                <span>
                  {item.first_name} {item.last_name}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const handleSearch = async (e) => {
    const {
      target: { value }
    } = e;
    const res = await AppService.searchData('employee', value);
    if (res && res.status === 200) {
      setEmployeesList(res.data);
      setError('');
    }
    if (res && res.status === 404) {
      setError(res);
    }
  };

  return (
    <Box>
      <Box>
        <Formik
          initialValues={{
            employee: []
          }}
          onSubmit={async (values) => {
            const res = await StaffService.addEmployees(
              param.id,
              checkedCheckBox
            );

            const status = res && res.status;
            if (status === 200) {
              enqueueSnackbar('User added Successfully', {
                variant: 'success'
              });
              setUpdateUserList(!updateUserList);
              await getStaff();
            } else {
              res?.msg.errors.map((item) =>
                enqueueSnackbar(item.message, {
                  variant: 'error'
                })
              );
              setOpenUser(false);
              await getStaff();
            }
            setOpenUser(false);
          }}
          children={({ handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit} className="addUserModal">
                <InputSearch handleSearch={handleSearch} border />
                <div className="userList">{renderEmployeeList()}</div>
                <Button
                  className="mt-4"
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                >
                  Create
                </Button>
              </Form>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default AddUser;
