/* eslint-disable no-console */
import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useStopwatch } from 'react-timer-hook';
import AppResource from '../../../utils';

const ItemList = ({ item }) => {
  const stopwatchOffset = new Date();

  const seconds =
    (new Date().getTime() - new Date(item?.publication_date).getTime()) / 1000;

  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + seconds);
  const { minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset
  });
  const hourTime = hours < 10 ? `0${hours}` : `${hours}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return (
    <Box className="visiterItem">
      <Box className="item-list-top">
        <Box>
          <span style={{ color: AppResource.colors.blueColor }}>
            Token No. {item.tokens}
          </span>
        </Box>
        <Box>
          <small className="item-list-name">{item?.visitor_name || ''}</small>
        </Box>
      </Box>
      <Box className="item-list-bottom">
        <Box>
          <small style={{ fontSize: '10px' }}>Arrival Time</small>
          <br />
          <span style={{ color: AppResource.colors.greyColor2 }}>
            {`${moment(item?.publication_date).format('HH:mm')} hrs`}
          </span>
        </Box>
        <Box>
          <small style={{ fontSize: '10px' }}>Waiting Time</small>
          <br />
          <span style={{ color: AppResource.colors.greyColor2 }}>
            <span>{hourTime}</span>:<span>{minuteTime}</span> hrs
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemList;
