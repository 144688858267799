import { Box } from '@mui/material';
import React from 'react';

function CardChart(props) {
  const { background } = props;
  return (
    <Box
    className="dashboard-visitor"
      style={{
        background,
      }}
    >
      <Box className="countBox">
        {' '}
        <span>34</span>{' '}
      </Box>
      <Box className="bottomText align-items-center">
        <span className="fw-600 h-20 text-white">Currently Wait</span>
        <span className="text-white h-16">Users</span>
      </Box>
    </Box>
  );
}

export default CardChart;
