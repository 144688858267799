/* eslint-disable no-console */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
import AuthService from '../../services/AuthService';
import Time from '../../utils/time';
import Logo from '../../assets/logo/Logo';
import loginBg from '../../assets/images/login-background.png';

const styles = {
  root: {
    background: `transparent url("${loginBg}") 0% 0% no-repeat padding-box`
  }
};

function SingIng() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleLoginSubmit = async (values) => {
    const res = await AuthService.login(values);
    if (res?.status === 200) {
      enqueueSnackbar('Login Successfully', {
        variant: 'success'
      });
      navigate('/app');
    } else {
      res?.data.errors.map((item) =>
        enqueueSnackbar(`${item.field} : ${item.message}`, {
          variant: 'error'
        })
      );
    }
  };

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      enableReinitialize
      onSubmit={(values) => {
        handleLoginSubmit(values);
      }}
      children={({ handleSubmit }) => {
        return (
          <Box style={styles.root} className="login-page">
            <Box className="formcontainer">
              <Box className="date">
                <Time />
              </Box>
              <Box className="logocontainer">
                <Logo height="49px" width="100px" />
                <Form>
                  <h2 className="qconsole-title">Log in</h2>
                  <Box>
                    <Field
                      id="username"
                      name="username"
                      type="text"
                      label="User ID"
                      component={FormikInputField}
                    />
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      component={FormikInputField}
                    />
                  </Box>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate('/forgot-password');
                    }}
                    sx={{ ml: 7, mt: 3 }}
                    className="pwdForget"
                  >
                    Forgot Password?
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="large"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Log in
                  </Button>
                </Form>
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
}

export default SingIng;
