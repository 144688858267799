/* eslint-disable no-console */
/* eslint-disable camelcase */
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import FormikInputField from 'components/Fields/FormikInputField';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import CounterService from 'services/CounterService';
import CloseIcon from '@mui/icons-material/Close';
import AppLoader from 'components/loading/AppLoader';

const AddCounter = ({
  open,
  handleClose,
  getCounters,
  editColumn,
  isUpdate
}) => {
  const userDetail = localStorage.getItem('token');
  const decodedToken = jwt_decode(userDetail);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState();
  const [servicesData, setServicesData] = useState();
  const [loading, setLoading] = useState(true);

  const array = [];
  async function getServiceData() {
    const res = await CounterService.getServicesList();
    setServicesData(res?.data);
    const ids = editColumn?.services.filter((obj) =>
      editColumn.services.some((obj2) => obj.id === obj2.id)
    );
    setId(ids);
  }

  useEffect(() => {
    getServiceData();
  }, []);

  const handleCounterSubmit = async (values) => {
    if (isUpdate) {
      const res = await CounterService.editCounter(editColumn.id, values);
      const status = res && res.status;
      if (status === 200) {
        enqueueSnackbar('Counter Edited Successfully', {
          variant: 'success'
        });
        setLoading(false);
        await getCounters();
      } else {
        res?.msg.errors.map((item) =>
          enqueueSnackbar(item.message, {
            variant: 'error'
          })
        );
        await getCounters();
      }
      handleClose();
    } else {
      const res = await CounterService.createCounter(values);
      const status = res && res.status;
      if (status === 201) {
        enqueueSnackbar('Counter added Successfully', {
          variant: 'success'
        });
        setLoading(false);
        await getCounters();
      } else {
        res?.msg.errors.map((item) =>
          enqueueSnackbar(item.message, {
            variant: 'error'
          })
        );
        await getCounters();
      }
      handleClose();
    }
  };

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  const servicesIdArray = [];
  editColumn?.services.map((item) => servicesIdArray.push(item.id));

  return (
    <>
      <Formik
        initialValues={{
          name: editColumn?.name || '',
          services: servicesIdArray || [],
          company: decodedToken?.company,
          counter_owner: decodedToken && decodedToken.user_id
        }}
        enableReinitialize
        onSubmit={(values) => {
          handleCounterSubmit(values);
        }}
        children={({ handleSubmit, setFieldValue }) => {
          return (
            <Box>
              <Dialog open={open} onClose={handleClose}>
                <Form>
                  <DialogTitle className="fw-700">
                    {isUpdate ? 'Edit Counter' : 'Add New Counter'}
                    <Button onClick={handleClose} className="closeIcon">
                      <CloseIcon />
                    </Button>
                  </DialogTitle>
                  <DialogContent>
                    <Field
                      name="name"
                      type="text"
                      label="Counter Name"
                      component={FormikInputField}
                    />

                    {isUpdate && id !== undefined && (
                      <Autocomplete
                        multiple
                        name="services"
                        id="services"
                        options={servicesData || []}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) =>
                          value.map((row, index) => {
                            array.push(row.id);
                            setFieldValue('services', array);
                          })
                        }
                        defaultValue={id}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Add Services" />
                        )}
                      />
                    )}

                    {!isUpdate && id === undefined && (
                      <Autocomplete
                        multiple
                        name="services"
                        id="services"
                        options={servicesData || []}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) =>
                          value.map((row, index) => {
                            array.push(row.id);
                            setFieldValue('services', array);
                          })
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Add Services" />
                        )}
                      />
                    )}

                    <Button
                      className="mt-2"
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleSubmit}
                      fullWidth
                      size="large"
                    >
                      {isUpdate ? 'Update' : 'Create'}
                    </Button>
                  </DialogContent>
                </Form>
              </Dialog>
            </Box>
          );
        }}
      />
      {renderLoader()}
    </>
  );
};

export default AddCounter;
