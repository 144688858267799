/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import { Box, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import jwt_decode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import CounterService from 'services/CounterService';
import AppModal from 'components/modal';
import AppResource from '../../utils';
import Delete from '../../assets/logo/delete.svg';
import Edite from '../../assets/logo/edit.svg';
import AddCounter from './components/AddCounter';

const ListCounter = ({ counterData, getCounters, NoDataFound }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openEdit, setOpenEdit] = useState(false);
  const [editColumn, setEditColumn] = useState({});
  const [deleteCounterId, setDeleteCounterId] = useState();
  const [deleteCounter, setDeleteCounter] = useState(false);

  const userDetail = localStorage.getItem('token');
  const decodedToken = jwt_decode(userDetail);

  const handleEdit = (e, row) => {
    setOpenEdit(true);
    setEditColumn(row);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  const handleDelete = (e, id) => {
    setDeleteCounter(true);
    setDeleteCounterId(id);
  };

  const confirmDeleteCounter = async () => {
    const res = await CounterService.deleteCounter(deleteCounterId);
    const toasterMsg = res && res.data && res.data.detail;
    const status = res && res.status;
    if (status === 200) {
      enqueueSnackbar(toasterMsg, {
        variant: 'success'
      });
      await getCounters();
    } else {
      res?.msg.errors.map((item) =>
        enqueueSnackbar(item.message, {
          variant: 'error'
        })
      );
      await getCounters();
    }
    setDeleteCounter(false);
    return res;
  };

  const renderServices = (row) => {
    if (row && row.services) {
      return row.services.map((service) => {
        return (
          <small key={service.id} className="badge badge-warning">
            {service?.name}
          </small>
        );
      });
    }
    return 'NA';
  };

  const renderActions = (row) => {
    if (decodedToken?.is_manager === 'yes') {
      return (
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          <IconButton
            onClick={(e) => handleDelete(e, row.id)}
            aria-label="delete"
          >
            <img src={Delete} alt="" />
          </IconButton>
          <IconButton
            onClick={(e) => handleEdit(e, row)}
            sx={{ ml: 1 }}
            aria-label="edit"
          >
            <img src={Edite} alt="" />
          </IconButton>
        </TableCell>
      );
    }
    return null;
  };

  const renderCounterData = () => {
    if (counterData?.res === 'Counter are not available!') {
      return (
        <TableCell
          colSpan={decodedToken?.is_manager === 'yes' ? 7 : 6}
          align="center"
          style={{ borderBottom: 'none', textTransform: 'capitalize' }}
        >
          {counterData.res}
        </TableCell>
      );
    }
    if (counterData?.res === 'You are not the member of the staff!!') {
      return (
        <TableCell
          colSpan={decodedToken?.is_manager === 'yes' ? 7 : 6}
          align="center"
          style={{ borderBottom: 'none', textTransform: 'capitalize' }}
        >
          {counterData.res}
        </TableCell>
      );
    }
    if (NoDataFound && NoDataFound.status === 404) {
      return (
        <TableCell
          colSpan={6}
          align="center"
          style={{ borderBottom: 'none', textTransform: 'capitalize' }}
        >
          {NoDataFound.msg.res}
        </TableCell>
      );
    }
    return counterData?.map((row) => (
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          sx={{ color: AppResource.colors.greyColor2 }}
          component="th"
          scope="row"
        >
          {row.name}
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          {moment(row.publication_date).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          {renderServices(row)}
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          -
        </TableCell>
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          -
        </TableCell>
        {renderActions(row)}
      </TableRow>
    ));
  };

  const renderAction = () => {
    if (decodedToken?.is_manager === 'yes') {
      return (
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          Actions
        </TableCell>
      );
    }
    return null;
  };

  return (
    <Box className="rounded-15 table-list-wrap">
      <TableContainer
        className="rounded-15"
        component={Paper}
        sx={{ boxShadow: 'none', p: 5 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: AppResource.colors.greyColor2 }}>
                Counter Name
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Date Created
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Services
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Total Customer Served
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Last Staff Operator
              </TableCell>
              {renderAction()}
            </TableRow>
          </TableHead>
          <TableBody>{renderCounterData()}</TableBody>
        </Table>
      </TableContainer>
      <AppModal
        handleClose={() => setDeleteCounter(false)}
        open={deleteCounter}
        title="Delete counter"
      >
        <Box>
          <span className="d-block h-20">
            Are you sure, you want to delete ?
          </span>
          <div className="d-flex justify-content-end">
            <Button
              className="mt-4"
              variant="contained"
              color="warning"
              type="submit"
              onClick={(e) => confirmDeleteCounter(e)}
            >
              Delete
            </Button>
          </div>
        </Box>
      </AppModal>
      {openEdit && (
        <AddCounter
          editColumn={editColumn}
          open={openEdit}
          handleClose={handleClose}
          isUpdate
          getCounters={getCounters}
        />
      )}
    </Box>
  );
};

export default ListCounter;
