/* eslint-disable no-console */
/* eslint-disable no-negated-condition */
/* eslint-disable camelcase */
import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import jwt_decode from 'jwt-decode';
import { Desk } from 'myApp/components/Desk';
import CallService from 'services/CallService';
import AppLoader from 'components/loading/AppLoader';
import { useSnackbar } from 'notistack';
import { useStopwatch } from 'react-timer-hook';
import AppResource from '../../utils';
import ButtonAction from './components/ButtonAction';
import ItemList from './components/ItemList';
import AppModal from '../../components/modal';
import TransferToken from './components/TransferToken';
import ItemListCounter from './components/ItemListCounter';
import TransferList from './components/TransferList';
import Time from '../../utils/time';

let websocket = null;
let callWebsocket = null;
let recallWebsocket = null;
let movebackWebsocket = null;
let transferWebsocket = null;

const Call = ({ assignedDesk, setAssignedDesk }) => {
  const { enqueueSnackbar } = useSnackbar();

  const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;
  const [counterTokenData, setCounterTokenData] = useState([]);
  const [visitorList, setVisitorList] = useState([]);
  const [counterDetail, setCounterDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [closeFlag, setCloseFlag] = useState(false);
  const [transferTokenList, setTransferTokenList] = useState([]);
  const [openConfirmation, setConfirmation] = useState(false);
  const [callData, setCallData] = useState();
  const [startFlag, setStart] = useState(false);
  const [serveToken, setServeToken] = useState(0);

  const { seconds, minutes, hours, days, start, reset } = useStopwatch({
    autoStart: false
  });

  const timeInHours = hours < 10 ? `0${hours}` : `${hours}`;
  const timeInSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const timeInMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const assignedDeskName = assignedDesk?.name;
  const assignedDeskId = assignedDesk?.id;

  const callButtonParameter = {
    message: {
      counter_id: counterDetail?.counter_id,
      employee_id: decodedDetail?.user_id
    }
  };

  async function getTransferTokenList() {
    const response = await CallService.getTransferTokenList();
    if (response?.data && response?.status === 200) {
      setTransferTokenList(response?.data);
    }
  }

  async function getServeToken() {
    const response = await CallService.getServeToken();
    if (response?.data?.res && response?.status === 200) {
      setServeToken(response?.data?.res);
    }
  }

  useEffect(() => {
    getServeToken();
  }, []);

  useEffect(() => {
    getTransferTokenList();
  }, []);

  async function getCountersTokens() {
    const res = await CallService.getCountersTokens();
    const statuss = res?.status;
    if (res?.data && statuss === 200) {
      setLoading(false);
      setCounterTokenData(res?.data);
    }
  }

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  async function getVisitorList() {
    const response = await CallService.getVisitorList(
      counterDetail?.counter_id
    );
    if (response?.data && response?.status === 200) {
      setVisitorList(response?.data?.list_of_tokens);
    }
  }

  useEffect(() => {
    if (counterDetail?.counter_id) getVisitorList();
  }, [counterDetail]);

  useEffect(() => {
    getCountersTokens();
  }, []);

  const [openTransferToken, setTransferToken] = useState(false);
  const [openSelectDesk, setSelectDeskToken] = useState(false);

  useEffect(() => {
    websocket = new WebSocket(`${WEB_SOCKET_URL}eventCall/`);

    websocket.onerror = function (error) {
      console.info('error: ', error);
    };
    websocket.onclose = (event) => {
      if (event.wasClean) {
        console.info('Socket connection closed!');
      } else {
        websocket = new WebSocket(`${WEB_SOCKET_URL}eventCall/`);
      }
    };
    return () => websocket.close();
  }, []);

  useEffect(() => {
    websocket.onmessage = (event) => {
      const json = JSON.parse(event?.data);
      if (json?.type === 'call_notification') {
        if (json?.message?.refresh === 'call_counter') {
          const user = json.message.active_employee.map((item) => {
            if (item === decodedDetail.user_id) {
              getCountersTokens();
              getVisitorList();
            }
          });
        }
        if (json?.message?.refresh === 'call_transfer') {
          if (json?.message?.transfer_by === decodedDetail.user_id) {
            getTransferTokenList();
          }
        }
      }
    };
  }, [counterDetail]);

  const callWebSocket = () => {
    callWebsocket = new WebSocket(`${WEB_SOCKET_URL}call/`);
    callWebsocket.onmessage = (event) => {
      const json = JSON.parse(event?.data);
      if (json?.type === 'call_data') {
        getCountersTokens();
        getVisitorList();
      }
    };
    callWebsocket.onerror = function (error) {
      console.info('error: ', error);
    };

    callWebsocket.onclose = (event) => {
      if (event.wasClean) {
        console.info('Socket connection closed!');
      } else {
        callWebsocket = new WebSocket(`${WEB_SOCKET_URL}call/`);
      }
    };
  };

  const recallWebSocket = () => {
    recallWebsocket = new WebSocket(`${WEB_SOCKET_URL}recall/`);

    recallWebsocket.onerror = function (error) {
      console.info('error: ', error);
    };

    recallWebsocket.onclose = (event) => {
      if (event.wasClean) {
        console.info('Socket connection closed!');
      } else {
        recallWebsocket = new WebSocket(`${WEB_SOCKET_URL}recall/`);
      }
    };
  };

  const movebackWebSocket = () => {
    movebackWebsocket = new WebSocket(`${WEB_SOCKET_URL}callback/`);

    movebackWebsocket.onerror = function (error) {
      console.info('error: ', error);
    };

    movebackWebsocket.onclose = (event) => {
      if (event.wasClean) {
        console.info('Socket connection closed!');
      } else {
        movebackWebsocket = new WebSocket(`${WEB_SOCKET_URL}callback/`);
      }
    };
  };

  const transferWebSocket = () => {
    transferWebsocket = new WebSocket(`${WEB_SOCKET_URL}transferCall/`);

    transferWebsocket.onerror = function (error) {
      console.info('error: ', error);
    };

    transferWebsocket.onclose = (event) => {
      if (event.wasClean) {
        console.info('Socket connection closed!');
      } else {
        transferWebsocket = new WebSocket(`${WEB_SOCKET_URL}transferCall/`);
      }
    };
  };

  useEffect(() => {
    callWebSocket();
    recallWebSocket();
    movebackWebSocket();
    transferWebSocket();
    return () => {
      callWebsocket.close();
      recallWebsocket.close();
      movebackWebsocket.close();
      transferWebsocket.close();
    };
  }, []);

  const handleClickOpen = () => {
    setTransferToken(true);
  };

  const handleClose = () => {
    setTransferToken(false);
  };

  const counterService = callData?.counter_service?.map((item) => item);

  const commonCalledApi = (json) => {
    getCountersTokens();
    getVisitorList();
    getTransferTokenList();
    getServeToken();
    setCallData(json?.message);
    start();
  };

  const handleClickCall = async (eve) => {
    callWebsocket.onmessage = (event) => {
      const json = JSON.parse(event?.data);
      if (json?.type === 'call_data') {
        getCountersTokens();
        getVisitorList();
      }
      if (
        json?.type === 'call_data' &&
        json?.message === 'Tokens are not available'
      ) {
        enqueueSnackbar(json?.message, {
          variant: 'error'
        });
      }
      if (
        json?.type === 'call_data' &&
        json?.message?.user_id === decodedDetail.user_id
      ) {
        commonCalledApi(json);
      }
    };

    callWebsocket.send(JSON.stringify(callButtonParameter));

    await setCloseFlag(false);
  };

  const handleClickRecall = (eve) => {
    recallWebsocket.onmessage = (event) => {
      const json = JSON.parse(event?.data);
      if (
        json?.type === 'recall_data' &&
        json?.message?.res === 'Currently you are not  serving any token.'
      ) {
        enqueueSnackbar(json?.message?.res, {
          variant: 'error'
        });
      }
      if (
        json?.type === 'recall_data' &&
        json?.message === 'Tokens are not available'
      ) {
        enqueueSnackbar(json?.message, {
          variant: 'error'
        });
      }
      if (
        json?.type === 'recall_data' &&
        json?.message?.user_id === decodedDetail.user_id
      ) {
        commonCalledApi(json);
      }
    };

    recallWebsocket.send(
      JSON.stringify({
        message: {
          counter_id: callData?.counter_id || null,
          employee_id: decodedDetail?.user_id
        }
      })
    );
    setCloseFlag(false);
  };

  const handleClickCallback = (eve) => {
    movebackWebsocket.onmessage = (event) => {
      const json = JSON.parse(event?.data);
      if (
        json?.type.toLowerCase() === 'callback_data' &&
        json?.message === 'Move Back tokens are not available'
      ) {
        enqueueSnackbar(json?.message, {
          variant: 'error'
        });
      }
      if (
        json?.type.toLowerCase() === 'callback_data' &&
        json?.message?.user_id === decodedDetail.user_id
      ) {
        commonCalledApi(json);
      }
    };

    movebackWebsocket.send(
      JSON.stringify({
        message: {
          counter_id: callData?.counter_id,
          employee_id: decodedDetail?.user_id
        }
      })
    );
    setCloseFlag(false);
  };

  const handleClickTransferToken = (e) => {
    transferWebsocket.onmessage = (event) => {
      const json = JSON.parse(event?.data);
      if (
        json?.type === 'trans_call_data' &&
        json?.message === 'Transfer tokens are not available'
      ) {
        enqueueSnackbar(json?.message, {
          variant: 'error'
        });
      }
      if (
        json?.type === 'trans_call_data' &&
        json?.message?.user_id === decodedDetail.user_id
      ) {
        setCallData(json?.message);
        getTransferTokenList();
        getServeToken();
        start();
      }
    };
    transferWebsocket.send(
      JSON.stringify({
        message: {
          user_id: decodedDetail?.user_id
        }
      })
    );

    setCloseFlag(false);
  };

  const handleStartPause = async (e) => {
    if (assignedDeskId && !startFlag) {
      const response = await CallService.getStartWorkTime(assignedDeskId);
      enqueueSnackbar(response?.data.message, {
        variant: 'success'
      });
      setStart(true);
    } else if (assignedDeskId && startFlag) {
      if (callData?.token_id) {
        if (closeFlag) {
          const response = await CallService.getPauseWorkTime(assignedDeskId);
          enqueueSnackbar(response?.data.message, {
            variant: 'success'
          });
          setStart(false);
        } else {
          enqueueSnackbar('Close token first then only you can pause.', {
            variant: 'error'
          });
        }
      } else if (callData === undefined) {
        const response = await CallService.getPauseWorkTime(assignedDeskId);
        enqueueSnackbar(response?.data.message, {
          variant: 'success'
        });
        setStart(false);
      } else if (callData === null) {
        const response = await CallService.getPauseWorkTime(assignedDeskId);
        enqueueSnackbar(response?.data.message, {
          variant: 'success'
        });
        setStart(false);
      }
    } else if (assignedDesk === undefined) {
      setSelectDeskToken(true);
    } else {
      return null;
    }
  };

  const handleCloseSelectDesk = () => {
    setSelectDeskToken(false);
  };

  const handleCloseButton = async () => {
    const data = {
      serve_by: decodedDetail?.user_id,
      tokens_id: callData?.token_id,
      counter_id: callData?.counter_id
    };
    const res = await CallService.close(data);
    if (callData?.token_id === undefined) {
      enqueueSnackbar(`Currently you are not serving any token.`, {
        variant: 'error'
      });
    }
    if (res && res.status === 200) {
      enqueueSnackbar(res?.data.message, {
        variant: 'success'
      });
      setCloseFlag(true);
      setCallData(null);
    }
    if (res && res.status === 404) {
      res?.data.errors.map((item) =>
        enqueueSnackbar(`${item.field} : ${item.message}`, {
          variant: 'error'
        })
      );
    }
    getServeToken();
    reset('', false);
  };

  const disabledCallButton = () => {
    if (
      assignedDeskName &&
      counterDetail &&
      startFlag &&
      counterTokenData?.total_number_of_counters_tokens.length !== 0 &&
      visitorList?.length !== 0
    ) {
      return '';
    }
    return 'disabled';
  };

  const disabledRecallAndMoveBack = () => {
    if (
      (assignedDeskName &&
        counterDetail &&
        startFlag &&
        counterTokenData?.total_number_of_counters_tokens.length !== 0 &&
        visitorList?.length !== 0) ||
      callData?.token_id
    ) {
      return '';
    }
    return 'disabled';
  };

  const disabledTranferButton = () => {
    if (
      (assignedDeskName && counterDetail && callData?.token_id && startFlag) ||
      callData?.token_id
    ) {
      return '';
    }
    return 'disabled';
  };

  const disabledTransferTokenButton = () => {
    if (assignedDeskName && startFlag && transferTokenList.length >= 0) {
      return '';
    }
    if (transferTokenList?.message) {
      return 'disabled';
    }
    return 'disabled';
  };

  const renderCallButton = () => {
    return (
      <ButtonAction
        bg="#1581FC"
        disabled={disabledCallButton()}
        onClick={(e) => handleClickCall(e)}
        colotxt="white"
        child={<Box>Call</Box>}
      />
    );
  };

  const renderRecallButton = () => {
    return (
      <ButtonAction
        bg="#D991FD"
        colotxt="white"
        disabled={disabledRecallAndMoveBack()}
        onClick={(e) => {
          handleClickRecall(e);
        }}
        child={<Box>Re-Call</Box>}
      />
    );
  };

  const renderMoveButton = () => {
    return (
      <ButtonAction
        bg="#FBDF47"
        colotxt="white"
        onClick={(e) => handleClickCallback(e)}
        disabled={disabledRecallAndMoveBack()}
        child={<Box>Move Back</Box>}
      />
    );
  };

  const renderTransferButton = () => {
    return (
      <ButtonAction
        bg="#5AD1B1"
        colotxt="white"
        onClick={() => handleClickOpen()}
        disabled={disabledTranferButton()}
        child={<Box>Transfer</Box>}
      />
    );
  };

  const renderServices = () => {
    if (counterDetail && counterDetail.services) {
      return counterDetail.services.map((item) => (
        <small key={item.id} className="badge badge-info d-inline-block">
          {item.name}
        </small>
      ));
    }
    return null;
  };

  const renderVisitorsList = () => {
    if (counterDetail?.counter_id && visitorList) {
      return visitorList.map((item, i) => (
        <ItemList key={item.id} item={item} />
      ));
    }
    return null;
  };

  return (
    <Box minWidth="sm md lg" className="call-page">
      <div className="d-flex common-top-head py-3 flex-wrap justify-content-between align-items-center">
        <h1>Call</h1>
        <Box className="d-flex align-items-center service-name">
          <span className="h-14 fw-500 d-inline-block mr-2 service-heading">
            Services
          </span>
          <Card className="shadow-none d-flex align-items-center">
            <CardContent>{renderServices()}</CardContent>
          </Card>
        </Box>
        <Time className="p-0" />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card className="common-box shadow-none pb-1">
                <span className="heading">Current Serving</span>
                <p className="grey-color">Token Number</p>
                <Box className="token-num">
                  <Box className="token-number d-flex justify-content-center align-items-center">
                    {!closeFlag && callData?.token_name}
                  </Box>
                  <div className="wating-time d-flex justify-content-between">
                    <div className="time-column">
                      <small className="grey-color">Waiting Time</small>
                      <div className="time-heading">
                        {!closeFlag && callData?.waiting_time
                          ? `${callData?.waiting_time} hrs`
                          : '00:00:00 hrs'}
                      </div>
                    </div>
                    <div className="time-column">
                      <small className="grey-color">Arrival Time</small>
                      <div className="time-heading">
                        {!closeFlag && callData?.arrival_time
                          ? `${callData?.arrival_time} hrs`
                          : '00:00:00 hrs'}
                      </div>
                    </div>
                  </div>
                </Box>
                <h3 className="pt-3 h-24">
                  {!closeFlag && callData?.visitor_name}
                </h3>
                <p className="pt-4">Serving Time</p>
                <span className="srvig-tile">
                  <span>{timeInHours}</span>:<span>{timeInMinutes}</span>:
                  <span>{timeInSeconds}</span>
                </span>
                <p className="pt-4">Service</p>
                <span className="heading">
                  {!closeFlag && callData?.user_id === decodedDetail.user_id ? (
                    <div>{counterService.join(' / ')}</div>
                  ) : (
                    ''
                  )}
                </span>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card className="shadow-none call-page-card rounded-15">
                <span className="d-block left-content-bottom-text ml-0 mb-3">
                  Counters
                </span>
                <Box components="div" className="">
                  <div className="counter-scroll pr-1">
                    {counterTokenData?.total_number_of_counters_tokens?.map(
                      (item, i) => (
                        <ItemListCounter
                          key={item.counter_id}
                          item={item}
                          counterDetail={counterDetail}
                          setCounterDetail={setCounterDetail}
                        />
                      )
                    )}
                  </div>
                </Box>
              </Card>
              <Card className="shadow-none call-page-card rounded-15 mt-3">
                <span className="ml-0 mb-3 d-block left-content-bottom-text">
                  Transfers
                </span>
                <Box components="div">
                  <div className="counter-scroll pr-1">
                    <TransferList transferTokenList={transferTokenList} />
                  </div>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Card className="d-flex mt-3 common-box py-4 shadow-none rounded-15">
            <Grid item xs={6} md={6}>
              <div>
                <span className="h-20">
                  <span className="text-primary">Total Serve Token:</span>{' '}
                  <b className="grey-color">{serveToken}</b>
                </span>
              </div>
            </Grid>

            <Grid item xs={6} md={6}>
              <div className="borderLeft pl-3">
                <span className="h-20">
                  <span className="text-primary"> Performance Status:</span>{' '}
                  <b className="grey-color">Excellent</b>
                </span>
              </div>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className="shadow-none call-page-card rounded-15">
            <div className="d-flex ml-0 mb-3 justify-content-between align-items-center">
              <span className="ml-0 left-content-bottom-text">
                Visitors List
              </span>
              <span
                className="mr-1"
                style={{ color: AppResource.colors.blueColor }}
              >
                Waiting:{' '}
                {counterDetail?.token_count && visitorList?.length
                  ? visitorList?.length
                  : '00'}
              </span>
            </div>
            <Box components="div">
              <div className="visitor-list-height pr-1">
                {renderVisitorsList()}
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Box className="my-3 button-action-row">
        {renderCallButton()}
        {renderRecallButton()}
        {renderMoveButton()}
        {renderTransferButton()}
        <ButtonAction
          bg="#F38684"
          disabled={disabledTransferTokenButton()}
          colotxt="white"
          onClick={(e) => handleClickTransferToken(e)}
          child={<Box>Transfer Token</Box>}
        />

        <ButtonAction
          bg="#F38684"
          colotxt="white"
          onClick={(e) => handleCloseButton(e)}
          child={<Box>Close</Box>}
        />
        <ButtonAction
          bg="white"
          colotxt="white"
          onClick={(e) => handleStartPause(e)}
          child={
            <Box>
              <Button
                variant="text"
                className="startBtn"
                startIcon={startFlag ? <PauseIcon /> : <PlayArrowIcon />}
              >
                <span className="text-black">
                  {startFlag ? 'Pause' : 'Start'}
                </span>
              </Button>
            </Box>
          }
        />
      </Box>
      {openTransferToken && (
        <AppModal
          handleClose={() => handleClose()}
          open={openTransferToken}
          title="Transfer current token to desk"
        >
          <TransferToken
            handleClose={handleClose}
            counterDetail={counterDetail}
            callData={callData}
            setCallData={setCallData}
            reset={reset}
          />
        </AppModal>
      )}
      {openSelectDesk && (
        <AppModal
          handleClose={() => handleCloseSelectDesk()}
          open={openSelectDesk}
          title="Select desk to continue"
        >
          <Desk
            selectDesk
            setSelectDeskToken={setSelectDeskToken}
            openSelectDesk={openSelectDesk}
            setConfirmation={setConfirmation}
            openConfirmation={openConfirmation}
            assignedDesk={assignedDesk}
            setAssignedDesk={setAssignedDesk}
          />
        </AppModal>
      )}
      {renderLoader()}
    </Box>
  );
};

export default Call;
