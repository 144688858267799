/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from './AuthHeader';
// const API_URL = "https://dev.qcloud.sencloud.net/"
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(data) {
    try {
      const response = await axios.post(`${API_URL}user/employee/token/`, data);
      if (response && response.data && response.data.access) {
        localStorage.setItem('token', response.data.access);
        localStorage.setItem('refresh', response.data.refresh);
      }
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async logout(data) {
    try {
      const response = await axios.post(`${API_URL}api/logout/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async forgotPassword(data) {
    try {
      const response = await axios.post(`${API_URL}request-reset-email/`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async resetPasswordComplete(data) {
    try {
      const response = await axios.patch(
        `${API_URL}password-reset-complete/`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async resetPassword(data) {
    try {
      const response = await axios.put(`${API_URL}change-password/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async handlePostRequest(route, data) {
    try {
      const response = await axios.post(API_URL + route, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async handlePutRequest(route, data) {
    try {
      const response = await axios.put(API_URL + route, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  logOut() {
    localStorage.clear();
  }
}

export default new AuthService();
