/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormikInputField from 'components/Fields/FormikInputField';
import { Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import AppService from 'services/AppService';
import StaffService from 'services/StaffService';

export const FormikFields = ({
  open,
  setOpen,
  handleSubmit,
  setFieldValue,
  isUpdate,
  staff
}) => {
  const [departmentData, setDepartmentData] = useState();
  const [servicesData, setServicesData] = useState();
  const [id, setId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [uploadedImageName, setUploadedImageName] = useState('');

  async function getDepartmentsData() {
    const res = await StaffService.getDepartmentsData();
    setDepartmentData(res.data);

    const ids = res.data?.filter((obj) => obj.id === staff?.department);
    setDepartmentId(ids);
  }

  const array = [];

  async function getServiceList() {
    const res = await AppService.getServiceList();
    setServicesData(res?.data);
    const ids = res?.data.filter((obj) =>
      staff?.service.some((obj2) => obj.id === obj2)
    );
    setId(ids);
  }

  useEffect(() => {
    getDepartmentsData();
    getServiceList();
  }, []);

  const handleUploadChange = (e) => {
    const obj = setFieldValue('upload_image', e.target.files[0]);
    const fileList = Array.from(e.target.files);
    const uploadedImage = fileList.map((item) => item.name);
    setUploadedImageName(uploadedImage);
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    formData.append('pic', fileList);
  };

  const handleReset = (e) => {
    setUploadedImageName(null);
  };

  const renderUploadImage = () => {
    if (uploadedImageName) {
      return (
        <Input
          value={uploadedImageName}
          onChange={handleUploadChange}
          disabled
          className="upload-file uploaded-file"
        />
      );
    }
    return (
      <Input
        name="upload_image"
        type="file"
        accept="image/*"
        onChange={handleUploadChange}
        className="upload-file"
      />
    );
  };

  return (
    <div>
      <Box>
        <Box>
          <Dialog open={open} onClose={() => setOpen(true)}>
            <DialogTitle>
              {isUpdate && staff !== undefined ? 'Edit Staff' : 'Add New Staff'}
              <Button onClick={() => setOpen(false)} className="closeIcon">
                <CloseIcon />
              </Button>
            </DialogTitle>

            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Field
                  name="name"
                  type="text"
                  label="Staff Name"
                  component={FormikInputField}
                />
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  component={FormikInputField}
                />
                <Field
                  name="contact"
                  type="text"
                  label="Contact Number"
                  component={FormikInputField}
                />

                {isUpdate && departmentId !== undefined && (
                  <Autocomplete
                    name="department"
                    id="department"
                    options={departmentData || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) =>
                      setFieldValue('department', value.id)
                    }
                    defaultValue={departmentId[0]}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Department" />
                    )}
                  />
                )}

                {departmentId === undefined && (
                  <Autocomplete
                    name="department"
                    id="department"
                    options={departmentData || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) =>
                      setFieldValue('department', value.id)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Add Department" />
                    )}
                  />
                )}

                {id && (
                  <Autocomplete
                    multiple
                    name="service"
                    id="service"
                    options={servicesData || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) =>
                      value.map((row, index) => {
                        array.push(row.id);
                        setFieldValue('service', array);
                      })
                    }
                    defaultValue={id}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Services" />
                    )}
                  />
                )}

                {id === undefined && (
                  <Autocomplete
                    multiple
                    name="service"
                    id="service"
                    options={servicesData || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) =>
                      value.map((row, index) => {
                        array.push(row.id);
                        setFieldValue('service', array);
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Add Services" />
                    )}
                  />
                )}

                <div className="uploaded-image position-relative">
                  {renderUploadImage()}
                  <Button onClick={(e) => handleReset(e)}>reset</Button>
                </div>

                {staff?.upload_image && (
                  <div className="text-center">
                    <img
                      style={{
                        maxWidth: '100%'
                      }}
                      className="staffUploadImg img-fluid"
                      src={staff.upload_image}
                      alt="staff"
                    />
                  </div>
                )}

                <div className="mt-2">
                  <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    size="large"
                    className="mt-4"
                  >
                    {isUpdate && staff !== undefined ? 'Update' : 'Create'}
                  </Button>
                </div>
              </DialogContent>
            </Form>
          </Dialog>
        </Box>
      </Box>
    </div>
  );
};
