/* eslint-disable no-console */
import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
import { useSnackbar } from 'notistack';
import AuthService from 'services/AuthService';
import Logo from '../../assets/logo/Logo';

function PasswordChange() {
  const token = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (values) => {
    if (values.reenterPassword && values.reenterPassword !== values.password) {
      enqueueSnackbar('Password does not match', {
        variant: 'error'
      });
    } else {
      const data = {
        password: values.password,
        token: token.token,
        uidb64: token.uidb64
      };
      const res = await AuthService.resetPasswordComplete(data);
      if (res?.status === 200) {
        enqueueSnackbar('Password reset successfully!', {
          variant: 'success'
        });
        navigate('/');
      } else {
        res?.data.errors.map((item) =>
          enqueueSnackbar(`${item.field} : ${item.message}`, {
            variant: 'error'
          })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{
        password: '',
        reenterPassword: ''
      }}
      enableReinitialize
      onSubmit={(values) => {
        handleResetPassword(values);
      }}
      children={({ handleSubmit }) => {
        return (
          <div className="reset-password-page">
            <Box className="resetPassword">
              <Box className="logoContainer">
                <Logo />
              </Box>
              <Form>
                <h2 className="qconsole-title">Reset Password</h2>
                <Box>
                  <Field
                    name="password"
                    type="password"
                    label="New Password"
                    component={FormikInputField}
                  />
                  <Field
                    name="reenterPassword"
                    type="password"
                    label="Re-enter Password"
                    component={FormikInputField}
                  />
                </Box>
                <Button
                  className="resetBtn"
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  onClick={handleSubmit}
                  fullWidth
                >
                  Reset
                </Button>
              </Form>
            </Box>
          </div>
        );
      }}
    />
  );
}

export default PasswordChange;
