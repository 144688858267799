/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, Formik } from 'formik';
import { Row, Col } from 'reactstrap';
import jwt_decode from 'jwt-decode';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import AppService from 'services/AppService';
import FormikInputField from 'components/Fields/FormikInputField';
import { AddServiceSchema } from 'common/ValidationSchemas/ValidationSchema';
import AppLoader from 'components/loading/AppLoader';
import ImageUploader from './components/ImageUploader';

const EditService = ({ setOpen, editRow, open, getServices }) => {
  const { id, name, description, acronym, upload_image: uploadImage } = editRow;

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(true);

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  const renderNameField = () => {
    return editRow?.parent_id === null ? (
      <Field
        name="name"
        type="text"
        label="Service Name"
        component={FormikInputField}
      />
    ) : (
      <Field
        name="name"
        type="text"
        label="Sub Service Name"
        component={FormikInputField}
      />
    );
  };

  const renderTitle = () => {
    return editRow?.parent_id === null ? 'Edit Service' : 'Edit Sub Service';
  };

  return (
    <Row>
      <Col>
        <Formik
          initialValues={{
            name,
            description,
            acronym,
            upload_image: '',
            company: decodedDetail && decodedDetail.company,
            department: decodedDetail && decodedDetail.department,
            owners: decodedDetail && decodedDetail.user_id
          }}
          enableReinitialize
          validationSchema={AddServiceSchema}
          onSubmit={async (values) => {
            const {
              name,
              description,
              acronym,
              // eslint-disable-next-line camelcase
              upload_image,
              company,
              department,
              owners
            } = values;
            // eslint-disable-next-line prefer-const
            let formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('acronym', acronym);
            formData.append('company', company);
            formData.append('department', department);
            formData.append('owners', owners);
            if (values.upload_image.name) {
              // eslint-disable-next-line camelcase
              formData.append('upload_image', upload_image, upload_image.name);
            }

            const res = await AppService.editService(id, formData);
            // eslint-disable-next-line eqeqeq
            if (res.status == 200) {
              enqueueSnackbar('Service edited Successfully', {
                variant: 'success'
              });
              setLoading(false);
              await getServices();
            } else {
              res?.msg.errors.map((item) =>
                enqueueSnackbar(item.message, {
                  variant: 'error'
                })
              );
              await getServices();
            }
            handleClose();
          }}
          children={({ handleSubmit, setFieldValue }) => {
            return (
              <>
                {' '}
                <Dialog open={open} onClose={handleClose}>
                  <Form>
                    <DialogTitle>
                      {renderTitle()}
                      <Button onClick={handleClose} className="closeIcon">
                        <CloseIcon />
                      </Button>
                    </DialogTitle>
                    <DialogContent>
                      {renderNameField()}

                      <Field
                        name="acronym"
                        type="text"
                        label="Acronym"
                        component={FormikInputField}
                      />

                      <Field
                        name="description"
                        type="text"
                        label="Description"
                        component={FormikInputField}
                      />

                      <ImageUploader
                        name="upload_image"
                        setFieldValue={setFieldValue}
                        uploadImage={uploadImage}
                        className="upload-file"
                      />

                      <Button
                        variant="contained"
                        type="button"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        size="large"
                        className="mt-4"
                      >
                        Update
                      </Button>
                    </DialogContent>
                  </Form>
                </Dialog>
                {renderLoader()}
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default EditService;
