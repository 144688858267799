/* eslint-disable no-console */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Field, Form, Formik } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
import { useSnackbar } from 'notistack';
import { ForgotPasswordSchema } from 'common/ValidationSchemas/ValidationSchema';
import Logo from '../../assets/logo/Logo';
import AuthService from '../../services/AuthService';

function ForgotPassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleForgotPassword = async (values) => {
    const res = await AuthService.forgotPassword(values);
    if (res?.status === 200) {
      enqueueSnackbar(res?.data?.success, {
        variant: 'success'
      });
      navigate('/email-indic', { state: values });
    } else {
      res?.data.errors.map((item) =>
        enqueueSnackbar(`${item.field} : ${item.message}`, {
          variant: 'error'
        })
      );
    }
  };

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={ForgotPasswordSchema}
      enableReinitialize
      onSubmit={(values) => {
        handleForgotPassword(values);
      }}
      children={({ handleSubmit }) => {
        return (
          <Box className="pb-5 forget-page">
            <Box className="forgotBox d-inline-block">
              <div className="d-flex logoRow align-items-center justify-content-between">
                <Logo height="49px" width="100px" />
                <Button
                  variant="text"
                  onClick={() => {
                    navigate('/login');
                  }}
                  className="mb-0 backBtn bg-transparent text-black p-0"
                >
                  <KeyboardBackspaceIcon />{' '}
                  <span className="ml-2">Back to login</span>
                </Button>
              </div>
              <Form>
                <h2 className="qconsole-title">Log in</h2>
                <Box>
                  <Field
                    name="email"
                    type="email"
                    label="Email Address"
                    component={FormikInputField}
                  />
                </Box>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  onClick={handleSubmit}
                  fullWidth
                >
                  Submit
                </Button>
              </Form>
            </Box>
          </Box>
        );
      }}
    />
  );
}

export default ForgotPassword;
