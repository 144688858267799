import React from 'react';
import { ErrorMessage } from 'formik';
import { FormGroup, TextField } from '@mui/material';

const FormikInputField = ({
  field: { ...fields },
  form: { touched, errors, setFieldValue, handleBlur },
  ...props
}) => {
  const { label, id } = props;
  const { name } = fields;

  const handleChange = (e) => {
    const { value } = e.target;
    setFieldValue(name, value, true);
  };

  return (
    <FormGroup>
      <TextField
        {...props}
        {...fields}
        id={id || 'outlined-basic'}
        placeholder={label}
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        margin="dense"
      />
      <ErrorMessage
        name={name}
        render={(msg) => {
          return <div className="errorMsg text-left m-0">{msg}</div>;
        }}
      />
    </FormGroup>
  );
};
export default FormikInputField;
