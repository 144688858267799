/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_URL;
class CounterService {
  async getCounters() {
    try {
      const response = await axios.get(`${API_URL}api/counters`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getServicesList() {
    try {
      const response = await axios.get(`${API_URL}api/service_list/details/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async createCounter(data) {
    try {
      const response = await axios.post(`${API_URL}api/counter/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async deleteCounter(data) {
    try {
      const response = await axios.delete(`${API_URL}api/counter/${data}/`, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }

  async editCounter(id, data) {
    try {
      const response = await axios.put(`${API_URL}api/counter/${id}/`, data, {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      return { status: error.response.status, msg: error.response.data };
    }
  }
}

export default new CounterService();
