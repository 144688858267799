/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { find } from 'lodash';
import jwt_decode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AppService from 'services/AppService';
import StaffService from 'services/StaffService';
import { Box, Button, Grid } from '@mui/material';
import AppModal from 'components/modal';
import Edite from '../../../assets/logo/edit.svg';
import Delete from '../../../assets/logo/delete.svg';
import User from '../../../assets/images/user.jpg';
import AddStaff from './AddStaff';

const ItemStaff = ({ staffs, getStaffs, NoDataFound }) => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [departmentData, setDepartmentData] = useState();
  const [deskData, setDeskData] = useState();
  const [serviceData, setServiceData] = useState([]);

  const [openEdit, setOpenEdit] = useState(false);
  const [staffData, setStaff] = useState();
  const [id, setId] = useState();

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const [deleteStaff, setDeleteStaff] = useState(false);
  const [deleteStaffId, setDeleteStaffId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getDepartmentsData() {
    const res = await StaffService.getDepartmentsData();
    setDepartmentData(res.data);
  }

  async function getDesksData() {
    const res = await StaffService.getDesksData();
    setDeskData(res.data);
  }

  async function getServiceData() {
    const res = await AppService.getServiceList();
    setServiceData(res?.data);
  }

  useEffect(() => {
    getDepartmentsData();
    getDesksData();
    getServiceData();
  }, []);

  const hanbleEditpage = (e, data) => {
    navigate(`/app/staff/${data.id}`);
  };

  const getStaff = async () => {
    if (id !== undefined) {
      const res = await StaffService.getStaffData(id);
      setStaff(res.data);
    }
  };

  useEffect(() => {
    getStaff();
  }, [id]);

  const handleEdit = (e, data) => {
    setOpenEdit(true);
    setId(data.id);
  };

  let departmentName = null;

  const renderDepartmentName = (staff) => {
    if (departmentData) {
      departmentName = find(departmentData, {
        id: staff && staff.department
      });
    }
    return departmentName && departmentName.name;
  };

  let deskName = null;

  const renderDeskName = (staff) => {
    if (deskData) {
      deskName = find(deskData, {
        id: staff && staff.desk
      });
    }
    return deskName && deskName.name;
  };

  const handleStaffDelete = (e, data) => {
    setDeleteStaff(true);
    setDeleteStaffId(data);
  };

  const confirmDeleteStaff = async () => {
    const res = await StaffService.deleteStaff(deleteStaffId);
    if (res && res.status === 200) {
      enqueueSnackbar('Staff Deleted Succesfully', {
        variant: 'success'
      });
      setDeleteStaff(false);
      await getStaffs();
    } else {
      res?.msg.errors.map((item) =>
        enqueueSnackbar(item.message, {
          variant: 'error'
        })
      );
      await getStaffs();
    }
    setDeleteStaff(false);
  };

  const renderServiceName = (staff) => {
    const serviceName = [];
    if (serviceData && staff && staff.service) {
      serviceData.map((d) => {
        if (staff.service.indexOf(d.id) >= 0) {
          serviceName.push(d);
        }
      });
    }
    return (
      <div>
        {serviceName.map((item) => {
          return (
            <Chip
              key={item.id}
              label={item.name}
              sx={{ bgcolor: '#FFFFFF', color: '#4B5662', marginLeft: '5px' }}
            />
          );
        })}
      </div>
    );
  };

  const renderDeleteEditButton = (staff) => {
    if (
      decodedDetail?.is_manager === 'yes' &&
      staff?.staff_manager === decodedDetail?.user_id
    ) {
      return (
        <>
          <img
            src={Delete}
            alt=""
            onClick={(e) => handleStaffDelete(e, staff.id)}
            aria-hidden="true"
          />
          <img
            onClick={(e) => handleEdit(e, staff)}
            src={Edite}
            alt=""
            aria-hidden="true"
          />
        </>
      );
    }
    return null;
  };

  const renderUploadImage = (staff) => {
    if (staff.upload_image)
      return <img className="img" src={staff.upload_image} alt="" />;
    return <img className="img" src={User} alt="" />;
  };

  const renderStaffsList = () => {
    if (NoDataFound && NoDataFound.msg && NoDataFound.msg.res) {
      return (
        <Grid style={{ textAlign: 'center' }} item xs={12}>
          {NoDataFound.msg.res}
        </Grid>
      );
    }
    if (staffs.length > 0) {
      return (
        <>
          {staffs?.map((staff) => {
            return (
              <Grid
                className="staffColumn pt-0"
                onDoubleClick={(e) => {
                  hanbleEditpage(e, staff);
                }}
                key={staff.id}
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                rowSpacing={2}
              >
                <div className="staffBoxWrapper">
                  <div className="action">{renderDeleteEditButton(staff)}</div>
                  {renderUploadImage(staff)}
                  <div className="info">
                    <h3 className="name">{staff.name}</h3>
                    <span className="designation">
                      {renderDepartmentName(staff)}{' '}
                      <span>- {renderDeskName(staff)}</span>
                    </span>
                    <span className="pt-2 text">{staff.email}</span>
                    <span className="text">{staff.contact}</span>
                    <span className="text">
                      {moment(staff.publication_date).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <div className="bottomAction">{renderServiceName(staff)}</div>
                </div>
              </Grid>
            );
          })}
          <AppModal
            handleClose={() => setDeleteStaff(false)}
            open={deleteStaff}
            title="Delete Staff"
          >
            <Box>
              <span className="d-block h-20">
                Are you sure, you want to delete ?
              </span>
              <div className="text-right">
                <Button
                  className="mt-4"
                  variant="contained"
                  color="warning"
                  type="submit"
                  onClick={(e) => confirmDeleteStaff(e)}
                >
                  Delete
                </Button>
              </div>
            </Box>
          </AppModal>
          {openEdit && staffData !== undefined && (
            <AddStaff
              open={openEdit}
              setOpen={setOpenEdit}
              staff={staffData}
              getStaffs={getStaffs}
              getStaff={getStaff}
              isUpdate
            />
          )}
        </>
      );
    }
    return (
      <Grid style={{ textAlign: 'center' }} item xs={12}>
        {staffs?.message}
      </Grid>
    );
  };

  return renderStaffsList();
};

export default ItemStaff;
