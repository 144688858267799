/* eslint-disable no-console */
/* eslint-disable camelcase */
import React from 'react';
import { Box } from '@mui/material';

const TransferList = ({ transferTokenList }) => {
  const renderTransferTokenList = () => {
    if (transferTokenList?.message) {
      return <div>{transferTokenList.message}</div>;
    }
    if (transferTokenList.length > 0) {
      return transferTokenList?.map((item) => {
        const {
          transfer_counter,
          transfer_by_name,
          transfer_desk_name,
          transfer_tokens_name
        } = item;
        return (
          <Box
            key={transfer_counter}
            component="div"
            className="transfer-list-item"
          >
            <Box component="div" className="d-flex justify-content-between">
              <Box component="div">
                <span className="d-block text-primary fw-600">Token No.</span>
                <h4 className="grey-color h-20">{transfer_tokens_name}</h4>
              </Box>
            </Box>
            <Box
              component="div"
              className="d-flex mt-2 justify-content-between align-items-end"
            >
              <Box component="div">
                <span className="d-block grey-color">Transfer by</span>
                <h4 className="grey-color h-16">{transfer_by_name}</h4>
              </Box>
              <Box component="div">
                <span className="d-block grey-color">{transfer_desk_name}</span>
              </Box>
            </Box>
          </Box>
        );
      });
    }
    return null;
  };

  return <>{renderTransferTokenList()}</>;
};
export default TransferList;
