/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { Container } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import ForgotPassword from 'pages/ForgotPassword';
import MyApp from './myApp/MyApp';
import AppResource from './utils';
import ChangePassword from './pages/ChangePassword';
import Login from './pages/Login';
import Summary from './pages/SummaryPage';
import Call from './pages/CallPage';
import Counter from './pages/CounterPage';
import Services from './pages/ServicesPage';
import Staff from './pages/StaffPage';
import Settings from './pages/Settings';
import StaffDetail from './pages/StaffPage/components/editStaff/StaffDetails';
import EmailIndicatore from './pages/EmaiIndicatore/EmailIndicatore';

import './styles/style.scss';

const App = () => {
  const [assignedDesk, setAssignedDesk] = useState();

  return (
    <SnackbarProvider maxSnack={5} autoHideDuration={1000}>
      <div className="App">
        <Container
          maxWidth="sm md lg"
          sx={{
            bgcolor: AppResource.colors.blColor,
            height: '100vh',
            overflow: 'hidden'
          }}
          style={{ position: 'absolute', top: '0', right: '0', padding: '0px' }}
        >
          <Router>
            <Routes>
              <Route path="/*" element={<Navigate to="/login" replace />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="password-reset/:uidb64/:token"
                element={<ChangePassword />}
              />
              <Route path="email-indic" element={<EmailIndicatore />} />
              <Route
                path="app"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Summary />
                  </MyApp>
                }
              />
              <Route
                path="app/summary"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Summary />
                  </MyApp>
                }
              />
              <Route
                path="app/call"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Call
                      assignedDesk={assignedDesk}
                      setAssignedDesk={setAssignedDesk}
                    />
                  </MyApp>
                }
              />
              <Route
                path="app/counter"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Counter />
                  </MyApp>
                }
              />
              <Route
                path="app/services"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Services />
                  </MyApp>
                }
              />
              <Route
                path="app/staff"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Staff />
                  </MyApp>
                }
              />

              <Route
                path="app/settings"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <Settings />
                  </MyApp>
                }
              />

              <Route
                path="app/staff/:id"
                element={
                  <MyApp
                    assignedDesk={assignedDesk}
                    setAssignedDesk={setAssignedDesk}
                  >
                    <StaffDetail />
                  </MyApp>
                }
              />
            </Routes>
          </Router>
        </Container>
      </div>
    </SnackbarProvider>
  );
};
export default App;
