/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import { useSnackbar } from 'notistack';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import jwt_decode from 'jwt-decode';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppService from 'services/AppService';
import AppResource from 'utils';
import AppModal from 'components/modal';
import EditService from '../EditService';
import AddService from '../AddService';
import { ParentTableRow } from './components/ParentTableRow';

const ServiceListing = ({ listData, getServices, NoDataFound }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selected, setSelected] = useState(-1);
  const [editRow, setEditRow] = useState('');
  const [open, setOpen] = useState(false);
  const [openAddSubService, setOpenAddSubService] = useState(false);
  const [addSubService, setAddSubService] = useState();

  const [deleteService, setDeleteService] = useState(false);
  const [deleteServiceId, setDeleteServiceId] = useState();

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const handleEdit = (e, data) => {
    setOpen(true);
    setEditRow(data);
  };

  const handleAddSubService = (e, data) => {
    setOpenAddSubService(true);
    setAddSubService(data);
  };

  const handleCloseAddSubService = () => {
    setOpenAddSubService(false);
  };

  const handleDelete = (e, data) => {
    setDeleteService(true);
    setDeleteServiceId(data);
  };

  const confirmDeleteService = async () => {
    const response = await AppService.deleteService(deleteServiceId.id);
    const toasterMsg = response && response.data && response.data.detail;
    const status = response && response.data && response.data.status;
    if (status === 200) {
      enqueueSnackbar(toasterMsg, {
        variant: 'success'
      });
      await getServices();
    } else {
      response?.msg.errors.map((item) =>
        enqueueSnackbar(item.message, {
          variant: 'error'
        })
      );
    }
    setDeleteService(false);
    return response;
  };

  const renderActions = () => {
    if (decodedDetail?.is_manager === 'yes') {
      return (
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          Actions
        </TableCell>
      );
    }
    return null;
  };

  const renderListData = () => {
    if (NoDataFound && NoDataFound.status === 404) {
      return (
        <TableRow>
          <TableCell
            colSpan={7}
            align="center"
            style={{ borderBottom: 'none', textTransform: 'capitalize' }}
          >
            {NoDataFound.msg.res}
          </TableCell>
        </TableRow>
      );
    }
    if (listData) {
      return listData?.map((row, index) => (
        <ParentTableRow
          key={row.id}
          selected={selected}
          row={row}
          index={index}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleAddSubService={handleAddSubService}
          setSelected={setSelected}
        />
      ));
    }
    return null;
  };

  return (
    <div className="table-list-wrap">
      <TableContainer
        className="rounded-15"
        component={Paper}
        sx={{ boxShadow: 'none', p: 5 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{}}>
              <TableCell />
              <TableCell sx={{ color: AppResource.colors.greyColor2 }}>
                Image
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Service
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Acronym
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Description
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Date Created
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                No. of Sub Services
              </TableCell>
              {renderActions()}
            </TableRow>
          </TableHead>
          <TableBody style={{ transition: '2s' }}>{renderListData()}</TableBody>
        </Table>
      </TableContainer>
      <AppModal
        handleClose={() => setDeleteService(false)}
        open={deleteService}
        title="Delete Service"
      >
        <Box>
          <span className="d-block h-20">
            Are you sure, you want to delete ?
          </span>
          <div className="text-right">
            <Button
              className="mt-4"
              variant="contained"
              color="warning"
              type="submit"
              onClick={(e) => confirmDeleteService(e)}
            >
              Delete
            </Button>
          </div>
        </Box>
      </AppModal>
      {open && (
        <EditService
          open={open}
          setOpen={setOpen}
          handleEdit={handleEdit}
          editRow={editRow}
          // eslint-disable-next-line react/jsx-no-bind
          getServices={getServices}
        />
      )}
      {openAddSubService && (
        <AddService
          addServiceForm={openAddSubService}
          addSubService={addSubService}
          handleClose={handleCloseAddSubService}
          // eslint-disable-next-line react/jsx-no-bind
          getServices={getServices}
        />
      )}
    </div>
  );
};

export default ServiceListing;
