import moment from 'moment';
import React, { useState } from 'react';
// import Clock from 'react-live-clock';

function Time() {
  const [time] = useState(new Date());
  return (
    <span className="hours px-0">
      <span>
        {`${time.toLocaleString('default', {
          month: 'long'
        })} ${time.toLocaleString('default', {
          day: 'numeric'
        })}, ${time.getFullYear()}  `}
        {/* <Clock format="HH:mm:ss" ticking Timezone="US/Pacific" /> */}
      </span>
      <span> | </span>
      <span>{moment().format('HH:mm:ss')}</span>
    </span>
  );
}

export default Time;
