/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Formik } from 'formik';
import jwt_decode from 'jwt-decode';
import { Row, Col } from 'reactstrap';
import { useSnackbar } from 'notistack';
import AppService from 'services/AppService';
import { AddServiceSchema } from 'common/ValidationSchemas/ValidationSchema';
import AppLoader from 'components/loading/AppLoader';
import { FormikFields } from './FormikFields';

const AddService = ({
  openAddSubService,
  addSubService,
  addServiceForm,
  handleClose,
  handleClickOpen,
  getServices
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  const renderLoader = () => {
    return loading && <AppLoader loading={loading} />;
  };

  return (
    <Row>
      <Col>
        <Formik
          initialValues={{
            name: '',
            description: '',
            acronym: '',
            upload_image: '',
            company: decodedDetail && decodedDetail.company,
            department: decodedDetail && decodedDetail.department,
            parent: addSubService && addSubService.id ? addSubService.id : '',
            owners: decodedDetail && decodedDetail.user_id,
            location: decodedDetail && decodedDetail.locations
          }}
          enableReinitialize
          validationSchema={AddServiceSchema}
          onSubmit={async (values) => {
            const {
              name,
              description,
              acronym,
              upload_image,
              company,
              department,
              owners,
              parent,
              location
            } = values;
            let formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('acronym', acronym);
            formData.append('company', company);
            formData.append('department', department);
            formData.append('parent', parent);
            formData.append('owners', owners);
            formData.append('location', location);
            if (values.upload_image.name) {
              formData.append('upload_image', upload_image, upload_image.name);
            }
            const res = await AppService.createService(formData);
            const status = res && res.status;
            if (status === 201) {
              enqueueSnackbar('Service added Successfully', {
                variant: 'success'
              });
              setLoading(false);
              await getServices();
            } else {
              res?.msg.errors.map((item) =>
                enqueueSnackbar(item.message, {
                  variant: 'error'
                })
              );
              await getServices();
            }
            handleClose();
          }}
          children={(props) => {
            return (
              <>
                <FormikFields
                  {...props}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  addServiceForm={addServiceForm}
                  openAddSubService={openAddSubService}
                  addSubService={addSubService}
                />
                {renderLoader()}
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default AddService;
