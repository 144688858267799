/* eslint-disable no-console */
import React from 'react';
import { useLocation } from 'react-router-dom';
import emailimg from './email.svg';

const EmailIndicatore = () => {
  const location = useLocation();
  return (
    <div className="email-root">
      <div className="email-box">
      <div className="email-root-content">
        <img className="img" src={emailimg} alt="" />
        <p className="p">
          A password reset link sent to you <br />
          {location.state.email}.
          <br /> Please check your inbox.
        </p>
      </div>
      </div>
    </div>
  );
};

export default EmailIndicatore;
