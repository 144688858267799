/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import jwt_decode from 'jwt-decode';
import FormikInputField from 'components/Fields/FormikInputField';
import { Field, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import Time from '../../utils/time';
import AuthService from '../../services/AuthService';

const Settings = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [user, setuser] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const userDetail = localStorage.getItem('token');
    const decodedDetail = jwt_decode(userDetail);
    setuser(decodedDetail);
  }, [setuser]);

  const handleChangePassword = async (values) => {
    if (
      values.confirm_password &&
      values.confirm_password !== values.new_password
    ) {
      enqueueSnackbar('Confirm Password does not match', {
        variant: 'error'
      });
    } else {
      const data = {
        username: user.username,
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_password: values.confirm_password
      };
      const res = await AuthService.resetPassword(data);
      if (res?.status === 200) {
        enqueueSnackbar(res?.data?.message, {
          variant: 'success'
        });
        setshowPassword(!showPassword);
      } else {
        enqueueSnackbar(`${res?.data?.status} : ${res?.data?.message}`, {
          variant: 'error'
        });
      }
    }
  };

  const firstName = user.first_name ? user.first_name.toLowerCase() : '';
  const lastName = user.last_name ? user.last_name.toLowerCase() : '';

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Box minWidth="sm md lg" className="setting-page">
      <Box className="px-0 d-flex align-items-center justify-content-between common-top-head">
        <h1>Settings</h1>
        <Box className="px-0">
          {' '}
          <Time />{' '}
        </Box>
      </Box>
      <Box className="rounded-15 common-box bg-white">
        <div className="profileBox">
          <h2 className="mt-0 mb-3 mb-md-4 h-24">Information</h2>
          <div className="lable-row">
            <span className="h-18 lableName fw-500">Account Holder Name</span>
            <span className="h-20 fw-600">
              <div className="text-capitalize d-inline-block">
                {`${firstName} ${lastName}`}
              </div>
            </span>
          </div>

          <div className="lable-row">
            <span className="h-18 lableName fw-500">Email Address</span>
            <span className="h-20 fw-600">{user.email}</span>
          </div>
          <div className="lable-row">
            <span className="h-18 lableName fw-500"> Contact Number</span>
            <span className="h-20 fw-600">{user.contact_number}</span>
          </div>
          <Box>
            {showPassword ? (
              <h2 className="mt-4 h-24 mb-3 mb-md-4">Change Password</h2>
            ) : (
              <Button
                className="mt-4 p-0 change-password-btn"
                onClick={() => {
                  setshowPassword(!showPassword);
                }}
                variant="text"
              >
                Change Password?
              </Button>
            )}
            {showPassword && (
              <Formik
                initialValues={{
                  old_password: '',
                  new_password: '',
                  confirm_password: ''
                }}
                enableReinitialize
                onSubmit={(values) => {
                  handleChangePassword(values);
                }}
                children={({ handleSubmit }) => {
                  return (
                    <div>
                      <Field
                        name="old_password"
                        type="password"
                        label="Old Password"
                        component={FormikInputField}
                      />

                      <Field
                        name="new_password"
                        type="password"
                        label="New Password"
                        component={FormikInputField}
                      />

                      <Field
                        name="confirm_password"
                        type="password"
                        label="Re-enter New Password"
                        component={FormikInputField}
                      />
                      <div className="pt-md-1">
                        <Button
                          onClick={handleSubmit}
                          size="large"
                          variant="contained"
                          className="mt-2 mt-md-3 mr-3"
                        >
                          Save
                        </Button>
                        <Button
                          className="mt-2 mt-md-3 p-0 change-password-btn"
                          onClick={() => {
                            setshowPassword(!showPassword);
                          }}
                          variant="text"
                        >
                          cancel
                        </Button>
                      </div>
                    </div>
                  );
                }}
              />
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Settings;
