import React from 'react';
import Time from '../../utils/time';

const SubTitle = ({ title, time }) => {
  return (
    <div className="common-top-head pb-4 mb-3 d-flex justify-content-between align-items-center">
      <h1>{title}</h1>
      <div>{time ? <Time /> : null}</div>
    </div>
  );
};

export default SubTitle;
