/* eslint-disable no-console */
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import InputSearch from 'components/form/InputSearch';
import AppModal from 'components/modal';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { List } from 'reactstrap';
import CallService from 'services/CallService';
import images from '../images.jpeg';

export const Desk = ({
  selectDesk,
  handleCloseDesk,
  openSelectDesk,
  setSelectDeskToken,
  openConfirmation,
  setConfirmation,
  assignedDesk,
  setAssignedDesk
}) => {
  const [selected, setSelected] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [desksList, setDesksList] = useState([]);

  async function getDesks() {
    const response = await CallService.getDesks();
    if (response?.data && response?.status === 200) {
      setDesksList(response?.data);
    }
  }

  const assignedDeskName = assignedDesk?.name;

  useEffect(() => {
    getDesks();
  }, []);

  const handleConfirmDesk = async () => {
    setSelectDeskToken(!openSelectDesk);

    const res = await CallService.deskSelect(selected?.id);
    if (res?.status === 200) {
      if (res?.data?.res === 'Desk is already assign to another user') {
        enqueueSnackbar(res?.data?.res, {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(res?.data?.res, {
          variant: 'success'
        });
        setAssignedDesk(selected);
      }
    } else {
      res?.data.errors.map((item) =>
        enqueueSnackbar(`${item.field} : ${item.message}`, {
          variant: 'error'
        })
      );
    }
    setConfirmation(false);
  };

  const handleClickConfirmation = async () => {
    if (selectDesk) {
      setConfirmation(true);
      handleCloseDesk();
    }
    if (!selectDesk) {
      const res = await CallService.deskSelect(selected?.id);
      if (res?.status === 200) {
        if (res?.data?.res === 'Desk is already assign to another user') {
          enqueueSnackbar(res?.data?.res, {
            variant: 'error'
          });
        } else {
          enqueueSnackbar(res?.data?.res, {
            variant: 'success'
          });
          setAssignedDesk(selected);
        }
      } else {
        res?.data.errors.map((item) =>
          enqueueSnackbar(`${item.field} : ${item.message}`, {
            variant: 'error'
          })
        );
      }
    }
    handleCloseDesk();
  };

  const handleClickDesk = (e, item) => {
    setSelected(item);
  };

  const renderDeskList = () => {
    if (desksList.length > 0) {
      return desksList.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            className={
              selected?.id === item?.id ? 'background-color-lightblue' : ''
            }
            secondaryAction={
              <span edge="end">
                {item.status === 1 ? (
                  'unavailable'
                ) : (
                  <span className="color_blue">Available</span>
                )}
              </span>
            }
            style={{ cursor: 'pointer' }}
            onClick={(e) => handleClickDesk(e, item)}
          >
            <ListItemAvatar>
              <Avatar>
                <img src={images} alt="" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <span>{item.name}</span>
              <p className="font-weight-normal">
                {item.occupied_by?.username || 'Vacant'}
              </p>
            </ListItemText>
          </ListItem>
        );
      });
    }
    return <div>{desksList?.res || null}</div>;
  };

  return (
    <>
      <Box className="transferToken">
        <InputSearch border />
        <List>{renderDeskList()}</List>
        <Button
          className="mt-4"
          variant="contained"
          fullWidth
          size="large"
          disabled={!selected || selected?.status === 1}
          onClick={() => handleClickConfirmation()}
        >
          {selectDesk ? 'Select Desk' : 'Change Desk'}
        </Button>
      </Box>
      {openConfirmation && (
        <AppModal
          handleClose={() => setConfirmation(false)}
          open={openConfirmation}
          title="Confirmation"
        >
          <Box className="confirmBox">
            <p className="h-18 grey-color mb-3">
              Are you sure you want to disconnect {assignedDeskName} ?
            </p>
          </Box>
          <Box className="text-right mt-4">
            <Button
              onClick={() => setConfirmation(false)}
              variant="outlined"
              size="large"
              className="mr-3 btnGrey"
            >
              No
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleConfirmDesk()}
            >
              Yes
            </Button>
          </Box>
        </AppModal>
      )}
    </>
  );
};
