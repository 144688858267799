/* eslint-disable camelcase */
/* eslint-disable prefer-const */
import { Box, Button, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import { useSnackbar } from 'notistack';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import jwt_decode from 'jwt-decode';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import StaffService from 'services/StaffService';
import AppResource from 'utils';
import AppModal from 'components/modal';
import Delete from '../../../../../../assets/logo/delete.svg';
import User from '../../../../../../assets/images/user.jpg';

const ListUser = ({ updateUserList, setUpdateUserList }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [staffmembers, setstaffmembers] = useState([]);
  const [staff, setStaff] = useState();
  const param = useParams();

  const [deleteUser, setDeleteUser] = useState(false);
  const [deleteUserRow, setDeleteUserRow] = useState();

  const userDetail = localStorage.getItem('token');
  const decodedDetail = jwt_decode(userDetail);

  useEffect(() => {
    async function getStaff() {
      const res = await StaffService.getStaffData(param.id);
      setStaff(res.data);
    }
    async function getStaffsMembers() {
      const res = await StaffService.getEmployees();
      setstaffmembers(res.data);
    }
    getStaffsMembers();
    getStaff();
  }, [param.id, updateUserList]);

  const handleDelete = (e, row) => {
    setDeleteUser(true);
    setDeleteUserRow(row);
  };

  const confirmDeleteUser = async () => {
    const data = {
      staff_id: parseInt(param.id, 10),
      employee_id: deleteUserRow.id
    };
    const response = await StaffService.deleteUser(data);
    const status = response && response.status;
    if (status === 200) {
      enqueueSnackbar('User deleted Successfully', {
        variant: 'success'
      });
      setUpdateUserList(!updateUserList);
    } else {
      enqueueSnackbar('User not added', {
        variant: 'error'
      });
    }
    setDeleteUser(false);
    return response;
  };

  let userList = [];
  if (staffmembers && staff && staff.employee) {
    staffmembers.map((d) => {
      if (staff.employee.indexOf(d.id) >= 0) {
        userList.push(d);
      }
    });
  }

  const renderDeleteAction = (row) => {
    if (
      decodedDetail?.is_manager === 'yes' &&
      staff?.staff_manager === decodedDetail?.user_id
    ) {
      return (
        <TableCell sx={{ color: AppResource.colors.greyColor2 }} align="left">
          <IconButton onClick={(e) => handleDelete(e, row)} aria-label="delete">
            <img src={Delete} alt="" />
          </IconButton>
        </TableCell>
      );
    }
    return null;
  };

  const renderUploadImage = (row) => {
    if (row.upload_image)
      return <img src={row.upload_image} className="user-round-image" alt="" />;
    return <img className="user-round-image" src={User} alt="" />;
  };

  const renderUserList = () => {
    if (userList) {
      return (
        <>
          {userList.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                component="th"
                scope="row"
              >
                {renderUploadImage(row)}
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                {row.first_name} {row.last_name}
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                {row.department}
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                {row.company}
              </TableCell>
              {renderDeleteAction(row)}
            </TableRow>
          ))}
        </>
      );
    }
    return 'No Users Found!';
  };

  return (
    <Box>
      <TableContainer
        className="rounded-15"
        component={Paper}
        sx={{ boxShadow: 'none', p: 5 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: AppResource.colors.greyColor2 }}>
                User
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Department
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Email
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Company
              </TableCell>
              <TableCell
                sx={{ color: AppResource.colors.greyColor2 }}
                align="left"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderUserList()}</TableBody>
        </Table>
      </TableContainer>
      <AppModal
        handleClose={() => setDeleteUser(false)}
        open={deleteUser}
        title="Delete Service"
      >
        <Box>
          <span className="d-block h-20">
            Are you sure, you want to delete ?
          </span>
          <div className="text-right">
            <Button
              className="mt-4"
              variant="contained"
              color="warning"
              type="submit"
              onClick={(e) => confirmDeleteUser(e)}
            >
              Delete
            </Button>
          </div>
        </Box>
      </AppModal>
    </Box>
  );
};

export default ListUser;
