import React from 'react';
import { Box } from '@mui/material';

const ButtonAction = ({ bg, colotxt, child, disabled, ...props }) => {
  return (
    <Box
      style={{
        background: bg,
        color: colotxt
      }}
      className={`buttonAction ${disabled}`}
      {...props}
    >
      {child}
    </Box>
  );
};

export default ButtonAction;
