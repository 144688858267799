/* eslint-disable no-console */
import React from 'react';
import { Box } from '@mui/material';
import AppResource from '../../../utils';
import valider from '../valider.svg';

const ItemListCounter = ({ item, setCounterDetail, counterDetail }) => {
  const handleClickCounter = (e, data) => {
    setCounterDetail(data);
    if (counterDetail?.counter_id === item.counter_id) {
      setCounterDetail(null);
    }
  };

  const renderSelectedCheck = () => {
    return counterDetail?.counter_id === item.counter_id ? (
      <Box className="triangle">
        <img src={valider} className="valider" alt="" />
      </Box>
    ) : null;
  };

  return (
    <Box
      className="itemlist d-flex align-items-center"
      sx={{ border: `1px solid ${AppResource.colors.blueColor}` }}
      onClick={(e) => handleClickCounter(e, item)}
    >
      <Box className="itemlistop">
        <span className="h-16 grey-color fw-500">{item.name}</span>
      </Box>
      <Box className="number ml-auto w-50 d-flex align-items-center justify-content-center">
        <span className="digit"> {item.token_count}</span>
        {renderSelectedCheck()}
      </Box>
    </Box>
  );
};

export default ItemListCounter;
