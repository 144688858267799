/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import AppService from 'services/AppService';
import { ChildTableRow } from './ChildTableRow';

const SubServiceListing = ({
  handleEdit,
  handleDelete,
  selected,
  handleAddSubService,
  row,
  setShowSubService,
  setRow,
  setIndex
}) => {
  const [subListData, setSubListData] = useState();

  const handleChangeSelected = (e, data, indexValue) => {
    setShowSubService(true);
    setRow(data);
    setIndex(indexValue);
  };
  async function getSubServiceData() {
    const res = await AppService.getSubServiceData(row.id);
    setSubListData(res);
  }
  useEffect(() => {
    getSubServiceData();
  }, [row]);

  const renderSubListData = () => {
    if (subListData && subListData.res) {
      return '';
    }
    return subListData?.map((subRow, indexSubRow) => (
      <ChildTableRow
        key={subRow.id}
        selected={selected}
        row={subRow}
        index={indexSubRow}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleAddSubService={handleAddSubService}
        handleChangeSelected={handleChangeSelected}
        subListData={subListData}
      />
    ));
  };

  return <>{renderSubListData()}</>;
};

export default SubServiceListing;
